import { useParams } from 'react-router-dom'
import NewEditCompanyForm from './NewEditCompanyForm'
import { selectCompanyById, useGetCompaniesQuery } from './companyApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'
import useAuth from '../../hooks/useAuth'
import { selectUserById, useGetUsersQuery } from '../users/usersApiSlice'
import { useSelector } from 'react-redux'

const NewEditCompany = () => {

    const { id: userId, isManager } = useAuth()
    console.log("userId: ", userId);
    const user = useSelector((state) => selectUserById(state, userId));
    console.log("user; ", user)
    let company = useSelector((state) => selectCompanyById(state, '64e619bd3f952827dc2573c6'));

    console.log("company: ", company);

    // Initialize an empty company if it doesn't exist
    if (!company) {
        company = {
            name: '',
            kennitala: '',
            email: '',
            phone: '',
            address: '',
            about: '',
            picture: '',
        };
    }

    if (isManager) { company.type = "Stofnun" } else { company.type = "Fyrirtæki" }
    if (userId) { company.user = userId }

    const content = <NewEditCompanyForm company={company} />

    return content
}
export default NewEditCompany