import React, { useEffect, useState } from "react";
import axios from "axios";
import { ThemeProvider, createTheme } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import DashLayout from "./components/DashLayout";
import MinarSidurLayout from "./components/MinarSidurLayout";
import Welcome from "./features/auth/Welcome";
import NotesList from "./features/notes/NotesList";
import UsersList from "./features/users/UsersList";
import EditUser from "./features/users/EditUser";
import NewUserForm from "./features/users/NewUserForm";
import EditNote from "./features/notes/EditNote";
import NewNote from "./features/notes/NewNote";
import Prefetch from "./features/auth/Prefetch";
import PersistLogin from "./features/auth/PersistLogin";
import RequireAuth from "./features/auth/RequireAuth";
import { ROLES } from "./config/roles";
import useTitle from "./hooks/useTitle";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import HomeLoggedIn from "./pages/HomeLoggedIn/HomeLoggedIn";
import NewBid from "./features/bid/NewBid";
import NewEditCompany from "./features/companies/NewEditCompany";
import theme from "./theme";
import { useAuthenticateMutation } from "./features/auth/authApiSlice";
import Authorize from "./components/Authorize";
import BidView from "./features/bid/BidView";
import Profile from "./pages/Profile/Profile";
import EditBid from "./features/bid/EditBid";

function App() {
  const [authenticate, { isLoading }] = useAuthenticateMutation();
  const [authResponse, setAuthResponse] = useState(null);

  useEffect(() => {
    async function requestAuth() {
      const response = await authenticate();
      setAuthResponse(response.data);
    }
    requestAuth();
  }, []);

  useTitle("Bidd"); 

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* public routes */}
          <Route index element={<Home />} />
          <Route path="login" element={<Login authResponse={authResponse} />} />
          <Route path="authorize" element={<Authorize />} />

          {/* Protected Routes */}
          <Route element={<PersistLogin />}>
            <Route
              element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}
            >
              <Route element={<Prefetch />}>
                <Route path="minar-sidur" element={<MinarSidurLayout />}>
                  <Route index element={<HomeLoggedIn />} />
                  <Route path="menu" element={<Welcome />} />
                  {/* Logged in routes */}
                  <Route
                    element={
                      <RequireAuth
                        allowedRoles={[
                          ROLES.Employee,
                          ROLES.Manager,
                          ROLES.Admin,
                        ]}
                      />
                    }
                  >
                    <Route path="skra-utbod" element={<NewBid />} />
                    <Route path="skra-utbod/:id" element={<EditBid />} />
                    <Route path="bid/:id" element={<BidView />} />
                    <Route path="profile" element={<Profile />} />

                    {/* Employee only Routes */}
                    <Route
                      element={
                        <RequireAuth
                          allowedRoles={[ROLES.Employee, ROLES.Admin]}
                        />
                      }
                    >
                      <Route
                        path="fyrirtaeki-uppl"
                        element={<NewEditCompany />}
                      ></Route>
                    </Route>
                    {/* Manager only Routes */}
                    <Route
                      element={
                        <RequireAuth
                          allowedRoles={[ROLES.Employee, ROLES.Admin]}
                        />
                      }
                    >
                      <Route
                        path="stofnun-uppl"
                        element={<NewEditCompany />}
                      ></Route>
                    </Route>
                    {/* Admin only Routes- TODO add later  */}
                    <Route path="users">
                      <Route index element={<UsersList />} />
                      <Route path=":id" element={<EditUser />} />
                      <Route path="new" element={<NewUserForm />} />
                    </Route>
                  </Route>
                  <Route path="notes">
                    <Route index element={<NotesList />} />
                    <Route path=":id" element={<EditNote />} />
                    <Route path="new" element={<NewNote />} />
                  </Route>
                </Route>
                {/* End Dash */}
              </Route>
            </Route>
          </Route>
          {/* End Protected Routes */}
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
