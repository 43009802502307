import NewBidForm from "./NewBidForm";
// use get bids
import { useGetBidsQuery } from "./bidsApiSlice";

import PulseLoader from "react-spinners/PulseLoader";
import useTitle from "../../hooks/useTitle";
import useAuth from "../../hooks/useAuth";

const NewBid = () => {    
  useTitle("bidd - skrá útboð");

  // const { users } = useGetUsersQuery("usersList", {
  //   selectFromResult: ({ data }) => ({
  //     users: data?.ids.map((id) => data?.entities[id]),
  //   }),
  // });

  const { id: userId } = useAuth();

  // const userId = users?.find((user) => user.name === name)?.id;

  // if (!users?.length) return <PulseLoader color={"#FFF"} />

  const content = <NewBidForm userId={userId} />; //<NewNoteForm users={users} />

  return content;
};
export default NewBid;
