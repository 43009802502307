import React, { useState, useEffect } from "react";
import styles from "../../styles/Profile.module.css";
import { useNavigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import { useGetBidsQuery } from "../../features/bid/bidsApiSlice";

const Profile = () => {
  const navigate = useNavigate();
  const { id, name, isManager, isAdmin } = useAuth();

  const {
    data: bids,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetBidsQuery("bidsList", {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [userBids, setUserBids] = useState([]);
  const [filteredBids, setFilteredBids] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      const { ids, entities } = bids;

      var userBids = [];
      for (const key in entities) {
        //if (isManager && entities[key].user === id) {
          userBids.push(entities[key]);
        //}
        //  else {
        //   userBids.push(entities[key]);
        // }
      }
    }
    setUserBids(userBids);
    setFilteredBids(userBids);
  }, [isSuccess]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.contentContainer}>
        {/* page content */}
        <div className={styles.pageContentContainer}>
          {/* top nav row */}
          <div className={styles.topNavRow}>
            {/* nav options */}
            <div className={styles.navOptions}>
              {/* <div className={styles.navOptionContainer}>
                <div className={styles.navOptionText}>Your Offer</div>
              </div> */}

              <div className={styles.navOptionContainerActive}>
                <div className={styles.navOptionText}>Mín útboð</div>
              </div>

              {/* <div className={styles.navOptionContainer}>
                <div className={styles.navOptionText}>Calendar</div>
              </div>

              <div className={styles.navOptionContainer}>
                <div className={styles.navOptionText}>Documents</div>
              </div>

              <div className={styles.navOptionContainer}>
                <div className={styles.navOptionText}>Communication</div>
              </div> */}
            </div>
            {/* filter dropdown */}
            {/* <div className={styles.filterDropDown}>
              <div className={styles.filterDropDownText}>Filter</div>
              <img src="/icons/bidd/chevron_down_filter.svg" alt="down arrow" />
            </div> */}
          </div>

          {/* divider */}
          <div className={styles.projectsDivider} />

          {/* projects */}
          <div className={styles.projectsGrid}>
            {filteredBids?.length > 0 &&
              filteredBids.map((bid) => (
                <div
                  className={styles.projectContainer}
                  key={bid._id}
                  onClick={() => {
                    //if (isManager) {
                      navigate(`/minar-sidur/skra-utbod/${bid._id}`);
                    //} else {
                    //  navigate(`/minar-sidur/bid/${bid._id}`);
                   // }
                  }}
                >
                  <div className={styles.imageContainer}>Bid Image</div>
                  <div className={styles.projectDetails}>
                    <div className={styles.projectName}>{bid.title}</div>
                    <div className={styles.projectDescription}>
                      {bid.description?.blocks[0].text + " ..."}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        {/* sidebar */}
        <div className={styles.notificationsContainer}>
          {/* heading */}
          <div className={styles.notificationsHeadingContainer}>
            <div className={styles.notificationsHeading}>Tilkynningar</div>
          </div>
          {/* notifications */}
          <div className={styles.notificationsBox}>
          <div className={styles.notificationContainer}>
              <img src="/icons/bidd/avatar.svg" alt="avatar" />
              <div className={styles.notificationContent}>
                <div className={styles.notificationTextContainer}>
                  <div className={styles.notificationHeading}>Bidd útboð</div>
                  <div className={styles.notificationText}>
                    Hér er einhver texti sem kemur í tilkynningu
                  </div>
                  <div className={styles.notificationTime}>2 tímar síðan</div>
                </div>
                <img src="/icons/bidd/vertical_dots.svg" alt="vertical dots" />
              </div>
            </div>
            {/* <div className={styles.notificationContainer}>
              <img src="/icons/bidd/avatar.svg" alt="avatar" />
              <div className={styles.notificationContent}>
                <div className={styles.notificationTextContainer}>
                  <div className={styles.notificationHeading}>Mass Transit</div>
                  <div className={styles.notificationText}>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque.
                  </div>
                  <div className={styles.notificationTime}>2 hours ago</div>
                </div>
                <img src="/icons/bidd/vertical_dots.svg" alt="vertical dots" />
              </div>
            </div>

            <div
              className={styles.notificationContainer}
              style={{ background: "var(--neutral-500, #EFEFF1)" }}
            >
              <img src="/icons/bidd/avatar_2.svg" alt="avatar" />
              <div className={styles.notificationContent}>
                <div className={styles.notificationTextContainer}>
                  <div className={styles.notificationHeading}>Bridge</div>
                  <div className={styles.notificationText}>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque.
                  </div>
                  <div className={styles.notificationTime}>5 hours ago</div>
                </div>
                <img src="/icons/bidd/vertical_dots.svg" alt="vertical dots" />
              </div>
            </div>

            <div className={styles.notificationContainer}>
              <img src="/icons/bidd/avatar_3.svg" alt="avatar" />
              <div className={styles.notificationContent}>
                <div className={styles.notificationTextContainer}>
                  <div className={styles.notificationHeading}>Construction</div>
                  <div className={styles.notificationText}>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque.
                  </div>
                  <div className={styles.notificationTime}>7 hours ago</div>
                </div>
                <img src="/icons/bidd/vertical_dots.svg" alt="vertical dots" />
              </div>
            </div>

            <div className={styles.notificationContainer}>
              <img src="/icons/bidd/avatar_4.svg" alt="avatar" />
              <div className={styles.notificationContent}>
                <div className={styles.notificationTextContainer}>
                  <div className={styles.notificationHeading}>Building</div>
                  <div className={styles.notificationText}>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque.
                  </div>
                  <div className={styles.notificationTime}>7 hours ago</div>
                </div>
                <img src="/icons/bidd/vertical_dots.svg" alt="vertical dots" />
              </div>
            </div>

            <div className={styles.notificationContainer}>
              <img src="/icons/bidd/avatar_5.svg" alt="avatar" />
              <div className={styles.notificationContent}>
                <div className={styles.notificationTextContainer}>
                  <div className={styles.notificationHeading}>
                    Road Transportation
                  </div>
                  <div className={styles.notificationText}>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque.
                  </div>
                  <div className={styles.notificationTime}>8 hours ago</div>
                </div>
                <img src="/icons/bidd/vertical_dots.svg" alt="vertical dots" />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
