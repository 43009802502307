import React from "react";
import styles from "../../styles/BidView.module.css";
import { useParams } from "react-router-dom";
import { useGetBidsQuery } from "./bidsApiSlice";
import { useGetDocumentsQuery } from "../documents/documentsApiSlice";
import useAuth from "../../hooks/useAuth";
import draftToHtml from "draftjs-to-html";
import axios from "axios";
import { Box, Button } from "@mui/material";

const BidView = () => {
  const { id } = useParams();
  const { name, isManager, isAdmin } = useAuth();

  const { bid } = useGetBidsQuery("bidsList", {
    selectFromResult: ({ data }) => ({
      bid: data?.entities[id],
    }),
  });

  const { documents } = useGetDocumentsQuery("documentsList", {
    selectFromResult: ({ data }) => {
      var bidDocuments = [];

      for (const key in data?.entities) {
        if (data?.entities[key].bid === id) {
          bidDocuments.push(data?.entities[key]);
        }
      }

      return {
        documents: bidDocuments,
      };
    },
  });

  // console.log("documents: ", documents);

  const downloadFile = (doc) => {
    console.log("downloading...", doc?.fileName);
    axios({
      // url: `https://bidd-backend-seven.vercel.app/documents/download/${filename}`,
      url: `${process.env.REACT_APP_BACKEND_URL}/documents/download/${doc?.fileName}`,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        // Create a download link for the file
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", doc?.documentName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  return (
    <>
      <div className={styles.mainContainer}>
        {/* contentContainer */}
        <div className={styles.contentContainer}>
          {/* page content */}
          <div className={styles.pageContentContainer}>
            {/* projects content */}
            <div className={styles.projectsContentContainer}>
              {/* projects title */}
              <div className={styles.projectsTitle}>{bid?.title}</div>
              {/* divider */}
              <div className={styles.projectsDivider} />
              {/* title Row */}
              {/* <div className={styles.titleRow}>
                {!(isManager || isAdmin) && (
                  <img src="/icons/bidd/starFilled.svg" alt="star" />
                )}
              </div> */}
              {/* industry */}
              <div className={styles.type}>
                <div className={styles.typeTitle}>Atvinnugrein</div>
                <div className={styles.typeValue}>{bid?.industry}</div>
              </div>
              {/* type */}
              <div className={styles.type}>
                <div className={styles.typeTitle}>Tegund</div>
                <div className={styles.typeValue}>{bid?.type}</div>
              </div>
              {/* cost */}
              <div className={styles.cost}>
                <div className={styles.costTitle}>Áætlaður kostnaður</div>
                <div className={styles.costValue}>
                  {Number(bid?.cost).toLocaleString("is-IS").replace(/,/g, ".")}
                  kr.
                </div>
              </div>
              {/* skill and price */}
              <div className={styles.skill}>
                <div className={styles.skillTitle}>Hæfni</div>
                <div className={styles.skillValue}>{bid?.skill}%</div>
                <div className={styles.skillTitle}>Verð</div>
                <div className={styles.skillValue}>{100 - bid?.skill}%</div>
              </div>
              {/* skill requirements */}
              {bid?.skillRequirements > 0 && (
                <div className={styles.skillRequirements}>
                  <div className={styles.skillRequirementsTitle}>
                    Hæfniskröfur
                  </div>
                  <div className={styles.skillRequirementsValue}>
                    {bid?.skillRequirements.map((skill, index) => (
                      <div>
                        {index + 1}. {skill}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/* document Requirements */}
              {/* <div className={styles.documentRequirements}>
                <div className={styles.documentRequirementsTitle}>
                  Nauðsynleg skjöl
                </div>
                <div className={styles.documentRequirementsValue}>
                  {bid?.documentRequirements.map((doc, index) => (
                    <div>
                      {index + 1}. {doc}
                    </div>
                  ))}
                </div>
              </div> */}
              {/* start Date */}
              <div className={styles.startDate}>
                <div className={styles.startDateTitle}>Dags. byrjar</div>
                <div className={styles.startDateValue}>
                  {new Date(bid?.startDate).toLocaleString("is-IS", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </div>
                <div className={styles.startDateTitle}>Dags. lýkur</div>
                <div className={styles.startDateValue}>
                  {new Date(bid?.endDate).toLocaleString("is-IS", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </div>
              </div>
              <div className={styles.startDate}>
                <div className={styles.startDateTitle}>Áætluð byrjun</div>
                <div className={styles.startDateValue}>
                  {new Date(bid?.predictedStartDate).toLocaleString("is-IS", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </div>
                <div className={styles.startDateTitle}>Áætlað lokið</div>
                <div className={styles.startDateValue}>
                  {new Date(bid?.predictedEndDate).toLocaleString("is-IS", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </div>
              </div>
              <br></br>
              {/* description */}
              <div className={styles.startDateTitle}>Lýsing</div>
              <div
                className={styles.textEditorView}
                dangerouslySetInnerHTML={{
                  __html: draftToHtml(bid?.description),
                }}
              />
              <br></br>
              {/* documents */}
              {documents.length > 0 && bid?.openClosed === "open" && (
                <div className={styles.documents}>
                  <div className={styles.documentsTitle}>Skjöl</div>

                  {/* docs Grid */}
                  <div className={styles.documentsGrid}>
                    {documents.map((document) => {
                      // let docLink = `https://bidd-backend-seven.vercel.app/documents/${document?.fileName}`;
                      let docLink = `${process.env.REACT_APP_BACKEND_URL}/documents/${document?.fileName}`;
                      return (
                        <div
                          className={styles.documentContainer}
                          key={document.id}
                          onClick={(e) => {
                            if (
                              document.fileMimetype.startsWith("image") ||
                              document.fileMimetype === "application/pdf" ||
                              document.fileMimetype === "text/plain"
                            ) {
                              window.open(docLink, "_blank");
                            } else {
                              return downloadFile(document);
                            }
                          }}
                        >
                          {/* thubnail container */}
                          <div className={styles.thumbnailContainer}>
                            <div className={styles.downloadRow}>
                              <img
                                src="/icons/bidd/download.svg"
                                alt="download-icon"
                                className={styles.downloadIcon}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  downloadFile(document);
                                }}
                              />
                            </div>

                            <img
                              className={
                                document.fileMimetype.startsWith("image")
                                  ? styles.documentIcon
                                  : null
                              }
                              src={
                                document.fileMimetype === "image/jpeg" ||
                                document.fileMimetype === "image/jpg" ||
                                document.fileMimetype === "image/png" ||
                                document.fileMimetype === "image/bmp" ||
                                document.fileMimetype === "image/gif" ||
                                document.fileMimetype === "image/svg+xml"
                                  ? docLink
                                  : document.fileMimetype === "application/pdf"
                                  ? "/icons/bidd/pdfFile.svg"
                                  : document.fileMimetype ===
                                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                    document.fileMimetype ===
                                      "application/msword" ||
                                    document.fileMimetype === "text/plain"
                                  ? "/icons/bidd/wordFile.svg"
                                  : document.fileMimetype ===
                                      "application/vnd.ms-excel" ||
                                    document.fileMimetype ===
                                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                  ? "/icons/bidd/excelFile.svg"
                                  : null
                              }
                              alt="Skjalamynd"
                            />
                          </div>
                          {/* name */}
                          <div className={styles.documentName}>
                            {document.documentName}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {/* create offer btn row */}
              {bid?.openClosed === "open" && (
                <Box className={styles.createOfferBtnRow}>
                  <Button variant="contained">Bjóða í útboð</Button>
                </Box>
              )}

              {bid?.openClosed !== "open" && (
                <Box className={styles.createOfferBtnRow}>
                  <Button variant="contained">
                    Biðja um aðgang að skjölum
                  </Button>
                </Box>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BidView;
