import React, { useEffect } from "react";
import styles from "../../styles/Home.module.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectCurrentToken } from "../../features/auth/authSlice";
import TextZoom from "../../components/TextZoom";
import ComputerIcon from '@mui/icons-material/Computer';
import GavelIcon from '@mui/icons-material/Gavel';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import ArticleIcon from '@mui/icons-material/Article';
import MessageIcon from '@mui/icons-material/Message';
import InsightsIcon from '@mui/icons-material/Insights';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import BusinessIcon from '@mui/icons-material/Business';
import EngineeringIcon from '@mui/icons-material/Engineering';

const Home = () => {
  const navigate = useNavigate();

  const token = useSelector(selectCurrentToken);

  useEffect(() => {
    if (token != null) navigate("/minar-sidur");
  }, [navigate, token]);

  return (
    <>
      <Header />
      <div className={styles.mainHeroContainer}>
        {/* hero container */}
        <div className={styles.heroContainer}>
          {/* details area */}
          <div className={styles.heroDetails}>
            <h1 className={styles.heroTitle}>
              <TextZoom />
              Fáðu <span className={styles.greenTxt}>Bidd.</span>
            </h1>
            <p className={styles.heroDescription}>
              Bidd er nýr útboðsvefur með það að markmiði að einfalda ferlið
              fyrir fyrirtæki að sækja sér utanaðkomandi þjónustu.
            </p>
            {/* <button className={styles.heroButton}>Nánar</button> */}
          </div>
          {/* image area */}
          <div className={styles.heroImageContainer}>
            <div className={styles.heroImageInsideContainer}>
              <img
                className={styles.HeroImageDesktop}
                src="/images/blodrur-mynd.svg"
                alt="blodrur-mynd"
              />
              <img
                className={styles.HeroImageMobile}
                src="/images/blodrur-mynd-litil.svg"
                alt="blodrur-mynd"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Content section 1  */}
      <div className={styles.contentSectionContainer}>
        <div className={styles.contentSection1}>
          <img
            className={styles.photoImageDesktop}
            src="/images/Bring_Solutions_To_Problem.svg"
            alt="photo"
          />
          <img
            className={styles.photoImageTablet}
            src="/images/Bring_Solutions_To_Problem.svg"
            alt="photo"
          />
          <img
            className={styles.photoImageMobile}
            src="/images/Bring_Solutions_To_Problem.svg"
            alt="photo"
          />
          {/* section details */}
          <div className={styles.contentSection1Details}>
            {/* details header */}
            <div className={styles.contentSection1DetailsHeader}>
              {/* heading */}
              <h2 className={styles.contentSection1DetailsTitle}>
                Fyrir hvern er Bidd?
              </h2>
              {/* description */}
            </div>
            {/* section features */}
            <div className={styles.contentSection1DetailsFeatures}>
              {/* feature */}
              <div className={styles.contentSection1DetailsFeature}>
                {/* feature icon */}
                <div
                  style={{
                    backgroundColor: "black",
                    padding: "3px 5px 3px 5px",
                    borderRadius: "50%",
                  }}
                >
                  <BusinessIcon sx={{ color: "#78ca72", fontSize: 40 }} />
                </div>
                {/* feature details */}
                <div className={styles.contentSection1DetailsFeatureDetails}>
                  {/* feature heading */}
                  <h3 className={styles.contentSection1DetailsFeatureTitle}>
                    Opinberar stofnanir
                  </h3>
                  {/* feature description */}
                  <p
                    className={styles.contentSection1DetailsFeatureDescription}
                  >
                    Bidd er útboðsvefur sem þróaður er sérstaklega með íslenskan markað í huga. Bidd ætlar sér að innleiða íslenska reglugerð til að þess auðvelda ferlið við að stofna útboð, m.a. með aðstoð máltækni.
                  </p>
                </div>
              </div>
              <div className={styles.contentSection1DetailsFeature}>
                {/* feature icon */}
                <div
                  style={{
                    backgroundColor: "black",
                    padding: "3px 5px 3px 5px",
                    borderRadius: "50%",
                  }}
                >
                  <EngineeringIcon sx={{ color: "#78ca72", fontSize: 40 }} />
                </div>
                {/* feature details */}
                <div className={styles.contentSection1DetailsFeatureDetails}>
                  {/* feature heading */}
                  <h3 className={styles.contentSection1DetailsFeatureTitle}>
                    Fyrirtæki
                  </h3>
                  {/* feature description */}
                  <p
                    className={styles.contentSection1DetailsFeatureDescription}
                  >
                    Bidd einfaldar ferlið fyrir fyrirtæki að sækja sér utanaðkomandi vöru/þjónustu. Með Bidd er hægt að tryggja að fyrirtæki séu raunverulega að fá besta verðið á sem skemmstum tíma. Vefurinn einfaldar einnig utanumhald verkefna.
                  </p>
                </div>
              </div>
              {/* feature */}
              <div className={styles.contentSection1DetailsFeature}>
                {/* feature icon */}
                <div
                  style={{
                    backgroundColor: "black",
                    padding: "3px 5px 3px 5px",
                    borderRadius: "50%",
                  }}
                >
                  <AssuredWorkloadIcon sx={{ color: "#78ca72", fontSize: 40 }} />
                </div>
                {/* feature details */}
                <div className={styles.contentSection1DetailsFeatureDetails}>
                  {/* feature heading */}
                  <h3 className={styles.contentSection1DetailsFeatureTitle}>
                    Verktakar
                  </h3>
                  {/* feature description */}
                  <p
                    className={styles.contentSection1DetailsFeatureDescription}
                  >
                    Bidd býður verktökum uppá ný og spennandi verkefni, koma sér sjálfum á framfæri og auðveldar utanumhald verkefna. Vefurinn býður uppá öfluga skjalageymslu til þess að einfalda ferlið við að bjóða í verkefni.
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
        {/* block footer */}
        {/* <div className={styles.contentSection1Footer}>
          <button className={styles.contentSection1FooterBtn1}>Skrá inn</button>
          <button className={styles.contentSection1FooterBtn2}>
            Búa til aðgang
          </button>
        </div> */}
      </div>

      {/* features container */}
      <div className={styles.featuresContainer}>
        {/* block header */}
        <div className={styles.featuresHeader}>
          {/* heading */}
          <h2 className={styles.featuresTitle}>Afhverju Bidd?</h2>
          {/*  subtitle */}
          <p className={styles.featuresSubtitle}>
          </p>
        </div>
        {/* features container */}
        <div className={styles.features}>
          <div className={styles.featuresGrid}>
            {/* feature */}
            <div className={styles.featureBox}>
              {/* top row */}
              <div className={styles.featureTopRow}>
                <div
                  style={{
                    backgroundColor: "#F5FAFF",
                    padding: "3px 5px 3px 5px",
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                >
                  <ComputerIcon sx={{ color: "#78ca72", fontSize: 40 }} />
                </div>
              </div>
              {/* feature details container */}
              <div className={styles.featureDetailsContainer}>
                {/* feature heading */}
                <h3 className={styles.featureDetailsTitle}>
                  Einfalt og notendavænt viðmót
                </h3>
                {/* feature description */}
                <p className={styles.featureDetailsDescription}>
                  Bidd leggur mikla áherslu á notendaupplifun og að hafa vefinn eins einfaldan og hægt er. Markmiðið er að hver sem er geti stofnað auglýsingar og boðið í verkefni, án þess að þurfa fylgja flóknum leiðbeiningum.
                </p>
              </div>
            </div>

            {/* feature */}
            <div className={styles.featureBox}>
              {/* top row */}
              <div className={styles.featureTopRow}>
                <div
                  style={{
                    backgroundColor: "#F5FAFF",
                    padding: "3px 5px 3px 5px",
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                >
                  <GavelIcon sx={{ color: "#78ca72", fontSize: 40 }} />
                </div>
              </div>
              {/* feature details container */}
              <div className={styles.featureDetailsContainer}>
                {/* feature heading */}
                <h3 className={styles.featureDetailsTitle}>
                  Sérsniðin að íslenskri reglugerð
                </h3>
                {/* feature description */}
                <p className={styles.featureDetailsDescription}>
                  Vefurinn verður sérsniðinn að lögum um opinber innkaup sem minnkar þörfina fyrir fyrirtæki og stofnanir að sækja sér sérfræðiþekkingu við stofnun útboðs. Í þessu felst mikil hagræðing, ásamt því að stytta ferlið til muna.
                </p>
              </div>
            </div>

            {/* feature */}
            <div className={styles.featureBox}>
              {/* top row */}
              <div className={styles.featureTopRow}>
                <div
                  style={{
                    backgroundColor: "#F5FAFF",
                    padding: "3px 5px 3px 5px",
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                >
                  <AutoModeIcon sx={{ color: "#78ca72", fontSize: 40 }} />
                </div>
              </div>
              {/* feature details container */}
              <div className={styles.featureDetailsContainer}>
                {/* feature heading */}
                <h3 className={styles.featureDetailsTitle}>
                  Sjálfvirknivæðing
                </h3>
                {/* feature description */}
                <p className={styles.featureDetailsDescription}>
                  Bidd ætlar sér að sjálfvirknivæða útboðsferlið til muna svo að starfsmenn geti einbeitt sér að öðrum mikilvægari virðisaukandi verkefnum, í staðinn fyrir að eyða tímanum sínum í staðlaða pappírsvinnu. Þetta er gert, m.a. með notkun á máltækni líkani og sjálfvirkri skjalageymslu.
                </p>
              </div>
            </div>

            {/* feature */}
            <div className={styles.featureBox}>
              {/* top row */}
              <div className={styles.featureTopRow}>
                <div
                  style={{
                    backgroundColor: "#F5FAFF",
                    padding: "3px 5px 3px 5px",
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                >
                  <ArticleIcon sx={{ color: "#78ca72", fontSize: 40 }} />
                </div>
              </div>
              {/* feature details container */}
              <div className={styles.featureDetailsContainer}>
                {/* feature heading */}
                <h3 className={styles.featureDetailsTitle}>
                  Rafrænir samningar{" "}
                </h3>
                {/* feature description */}
                <p className={styles.featureDetailsDescription}>
                  Bidd býður uppá að ljúka ferlinu með rafrænum samningum. Ásamt því mun vefurinn koma til með að geta haldið utan um mikilvægar dagsetningar og viðburði í samningunum, og bætt þeim inn í skipulag verkefnis á vefnum.
                </p>
              </div>
            </div>

            {/* feature */}
            <div className={styles.featureBox}>
              {/* top row */}
              <div className={styles.featureTopRow}>
                <div
                  style={{
                    backgroundColor: "#F5FAFF",
                    padding: "3px 5px 3px 5px",
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                >
                  <MessageIcon sx={{ color: "#78ca72", fontSize: 40 }} />
                </div>
              </div>
              {/* feature details container */}
              <div className={styles.featureDetailsContainer}>
                {/* feature heading */}
                <h3 className={styles.featureDetailsTitle}>
                  Einfalda samskipti
                </h3>
                {/* feature description */}
                <p className={styles.featureDetailsDescription}>
                  Í dag fara flest samskipti sem tengjast stórum viðskiptum í gegnum síma eða tölvupóst, og er því hætta á að mikilvæg skjöl týnist. Á Bidd eiga öll samskipti sér stað inni á vefnum, sem hjálpar við utanumhald verkefna og skjala.
                </p>
              </div>
            </div>

            {/* feature */}
            <div className={styles.featureBox}>
              {/* top row */}
              <div className={styles.featureTopRow}>
                <div
                  style={{
                    backgroundColor: "#F5FAFF",
                    padding: "3px 5px 3px 5px",
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                >
                  <InsightsIcon sx={{ color: "#78ca72", fontSize: 40 }} />
                </div>
              </div>
              {/* feature details container */}
              <div className={styles.featureDetailsContainer}>
                {/* feature heading */}
                <h3 className={styles.featureDetailsTitle}>
                  Öflugri gagnaúrvinnsla{" "}
                </h3>
                {/* feature description */}
                <p className={styles.featureDetailsDescription}>
                  Bidd gerir aðilum kleift að fylgjast betur með þróun markaða með rauntíma vakt á markaðsþróun og nýjum verkefnum. Ásamt þessu mun Bidd bjóða uppá á API tengingu fyrir opinbera aðila, til að geta rýnt betur í gögnin.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.hero}>
        <div className={styles.container1}>
          <div className={styles.heroBase}>
            <div className={styles.textContainer1}>
              <div className={styles.headlineSupportingText1}>
                <b className={styles.headline}>{`Framtíðarsýn `}</b>
                <div>
                  <div className={styles.floatLeft}>
                    <div className={styles.subtitle2}>
                      Möguleikarnir á nýtingu gagna sem verða til við útboðsferlið eru margvíslegir.
                      Bidd ætlar sér að nýta þessi gögn á þann veg að auðvelda fyrirtækjum kostnaðaráætlunargerð,
                      með því að nota gögn úr nýlegum kostnaðaráætlunum verktaka úr samskonar verkefnum.
                      Þetta mun gera aðilum kleift að búa til mun nákvæmari áætlanir en hægt er núna, á einfaldari hátt.
                      Ásamt þessu getur Bidd séð markaðsþróun eiga sér stað í rauntíma,
                      og þannig betur auðveldað m.a. opinberum aðilum að sameina verkefni og
                      innkaup og nýta þannig betur stærðarhagkvæmni í opinberum innkaupum.
                      Ásamt þessu gerir þetta aðilum kleift að sjá hvar mesta þörfin er,
                      og geta þeir þá betur breytt sínum viðskiptaháttum í takt við þá þróun og betur þjónustað fyrirtæki.
                    </div>
                  </div>
                  <div className={styles.gallery}>
                    <div className={styles.galleryRow}>
                      <img
                        className={styles.imageIcon}
                        alt="Baldvin-mynd"
                        src="/images/Baldvin_mynd.jpg"
                      />
                      <img
                        className={styles.imageIcon}
                        alt="Gylfi-mynd"
                        src="/images/Gylfi_mynd.jpg"
                      />
                    </div>
                    <div className={styles.galleryRow}>
                      <img
                        className={styles.imageIcon}
                        alt="Kristinn-mynd"
                        src="/images/Kristinn_mynd.jpg"
                      />
                      <img
                        className={styles.imageIcon}
                        alt="Leo-mynd"
                        src="/images/Leo_mynd.jpg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
