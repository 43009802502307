import { useState, useEffect } from "react";
import { styled } from "@mui/system";
import {
  FormControl,
  TextField,
  Button,
  Typography,
  FormHelperText,
} from "@mui/material";
import { useAddNewEditCompanyMutation } from "./companyApiSlice";

const FormContainer = styled('form')(({ theme }) => ({
  paddingTop: '60px',
  width: { xs: '100%', md: '60%' },
  maxWidth: '1264px',
  margin: 'auto',
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const NewEditCompanyForm = ({ company }) => {
  const [addNewEditCompany, { isLoading, isSuccess, isError, error }] =
    useAddNewEditCompanyMutation();

  const [name, setName] = useState(company.name);
  const [kennitala, setKennitala] = useState(company.kennitala);
  const [email, setEmail] = useState(company.email);
  const [phone, setPhone] = useState(company.phone);
  const [address, setAddress] = useState(company.address);
  const [about, setAbout] = useState(company.about);
  //const [picture, setPicture] = useState(company.picture);
  //const [documents, setDocuments] = useState(company.documents);  
  const [errors, setErrors] = useState({
    name: false,
    kennitala: false,
    email: false,
    phone: false,
    address: false,
    about: false,
    //picture: false
  });

  useEffect(() => {
    console.log(isSuccess);
    if (isSuccess) {
      //TODO show form has been saved 
    }
  }, [isSuccess]);



  const handleSubmit = async (e) => {
    const isValid = validateForm();

    console.log("company?._id ", company?._id);
    console.log("company.user ", company.user);
    console.log("name ", name );
    console.log("kennitala: ", kennitala);
    console.log("email: ", email);
    console.log("phone: ", phone);
    console.log("address: ", address);
    console.log("about: ", about);
    console.log("company.type: ", company.type);

    if (isValid) {
      await addNewEditCompany({
        company: company?._id,
        user: company.user,
        name,
        kennitala,
        email,
        phone,
        address,
        about,
        //picture,
        //document,
        type: company.type
      });
    }
  };

  const validateForm = () => {
    const newErrors = {
      name: name === "",
      kennitala: kennitala === "",
      email: email === "",
      phone: phone === "",
      address: address === "",
      about: about === "",
    };
    console.log("newErrors", newErrors);
    setErrors(newErrors);

    for (const error of Object.values(newErrors)) {
      if (error) {
        return false;
      }
    }
    return true;
  };

  const handleNameChange = (e) => {
    const newName = e.target.value;
    setName(newName);
    if (newName) {
      setErrors((prevErrors) => ({ ...prevErrors, name: false }));
    }
  };

  const handleKennitalaChange = (e) => {
    const newKennitala = e.target.value;
    setKennitala(newKennitala);
    if (newKennitala) {
      setErrors((prevErrors) => ({ ...prevErrors, kennitala: false }));
    }
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (newEmail) {
      setErrors((prevErrors) => ({ ...prevErrors, email: false }));
    }
  };

  const handlePhoneChange = (e) => {
    const newPhone = e.target.value;
    setPhone(newPhone);
    if (newPhone) {
      setErrors((prevErrors) => ({ ...prevErrors, phone: false }));
    }
  };

  const handleAddressChange = (e) => {
    const newAddress = e.target.value;
    setAddress(newAddress);
    if (newAddress) {
      setErrors((prevErrors) => ({ ...prevErrors, address: false }));
    }
  };

  const handleAboutChange = (e) => {
    const newAbout = e.target.value;
    setAbout(newAbout);
    if (newAbout) {
      setErrors((prevErrors) => ({ ...prevErrors, about: false }));
    }
  };

  const content = (
    <FormContainer>
      <Typography variant="h3">{company.type === "Stofnun" ? "Upplýsingar stofnunar" : "Upplýsingar fyrirtækis"}</Typography>
      <FormControl fullWidth error={errors.name}>
        <TextField
          label={company.type === "Stofnun" ? "Nafn á stofnun" : "Nafn á fyrirtæki"}
          value={name}
          onChange={(e) => handleNameChange(e)}
          fullWidth
          required
        />
        {errors.name && (
          <FormHelperText>Vinsamlegast skráðu nafn</FormHelperText>
        )}
      </FormControl>
      <FormControl fullWidth error={errors.kennitala}>
        <TextField
          label="Kennitala"
          value={kennitala}
          onChange={(e) => handleKennitalaChange(e)}
          fullWidth
          required
        />
        {errors.kennitala && (
          <FormHelperText>Vinsamlegast skráðu kennitölu</FormHelperText>
        )}
      </FormControl>
      <FormControl fullWidth error={errors.email}>
        <TextField
          label="Netfang"
          value={email}
          onChange={(e) => handleEmailChange(e)}
          fullWidth
          required
        />
        {errors.email && (
          <FormHelperText>Vinsamlegast skráðu netfang</FormHelperText>
        )}
      </FormControl>
      <FormControl fullWidth error={errors.phone}>
        <TextField
          label="Símanúmer"
          value={phone}
          onChange={(e) => handlePhoneChange(e)}
          fullWidth
          required
        />
        {errors.phone && (
          <FormHelperText>Vinsamlegast skráðu símanúmer</FormHelperText>
        )}
      </FormControl>
      <FormControl fullWidth error={errors.address}>
        <TextField
          label="Heimilisfang"
          value={address}
          onChange={(e) => handleAddressChange(e)}
          fullWidth
          required
        />
        {errors.address && (
          <FormHelperText>Vinsamlegast skráðu heimilisfang</FormHelperText>
        )}
      </FormControl>
      <FormControl fullWidth error={errors.about}>
        <TextField
          label="Lýsing"
          value={about}
          onChange={(e) => handleAboutChange(e)}
          fullWidth
          required
        />
        {errors.about && (
          <FormHelperText>Vinsamlegast skráðu lýsingu</FormHelperText>
        )}
      </FormControl>
      <div>
        <Button
          sx={{ float: 'right' }}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Vista
        </Button>
      </div>
    </FormContainer>
  );

  return content;
};
export default NewEditCompanyForm;
