import useWindowSize from "../utils/useWindowSize";
import styles from "../styles/Footer.module.css";
export default function Footer() {
  const windowSize = useWindowSize();
  return (
    <div>
    <div className={styles.footerContainer}>
      <div className={styles.content}>
        {/* col container */}
        <div className={styles.colsContainer}>
          {/* logo Col */}
          <div className={styles.logoCol}>
            <img src="/bidd_logo.svg" alt="bidd-logo" />
            <div className={styles.slogan}>
              <p>bidd@bidd.is</p>
              <p>610223-0440</p>
            </div>
          </div>

          {/* Resources Col */}
          {/* <div className={styles.resourcesColDesktop}> */}
          {/* Heading */}
          {/* <div className={styles.heading}>Resources</div> */}
          {/* Links Container */}
          {/* <div className={styles.linksContainer}>
                <div className={styles.link}>Community</div>
                <div className={styles.link}>Events</div>
                <div className={styles.link}>Help&nbsp;Center</div>
                <div className={styles.link}>Partners</div>
              </div>
            </div> */}

          {/* Products Col */}
          {/* <div className={styles.resourcesColDesktop}> */}
          {/* Heading */}
          {/* <div className={styles.heading}>Products</div> */}
          {/* Links Container */}
          {/* <div className={styles.linksContainer}>
                <div className={styles.link}>Integrations</div>
                <div className={styles.link}>Solutions</div>
                <div className={styles.link}>Features</div>
                <div className={styles.link}>Enterprise</div>
              </div>
            </div> */}

          {/* email Col */}
          {/* <div className={styles.emailCol}> */}
          {/* Heading */}
          {/* <div className={styles.heading}>Get Email Notifications</div>
              <div className={styles.slogan}>
                Generate outside the box thinking with the possibility to
                targtet the low.
              </div> */}
          {/* Email Container */}
          {/* <div className={styles.emailContainer}>
                <input
                  placeholder="Enter email...."
                  type="text"
                  className={styles.emailInput}
                />
                <div className={styles.emailSubmitButton}>Submit</div>
              </div>
            </div> */}
        </div>

        {/* cols container tablet */}
        {/* <div className={styles.colsContainerTablet}> */}
        {/* Resources Col */}
        {/* <div className={styles.resourcesColTablet}> */}
        {/* Heading */}
        {/* <div className={styles.heading}>Resources</div> */}
        {/* Links Container */}
        {/* <div className={styles.linksContainer}>
                <div className={styles.link}>Community</div>
                <div className={styles.link}>Events</div>
                <div className={styles.link}>Help&nbsp;Center</div>
                <div className={styles.link}>Partners</div>
              </div>
            </div> */}

        {/* Products Col */}
        {/* <div className={styles.resourcesColTablet}> */}
        {/* Heading */}
        {/* <div className={styles.heading}>Products</div> */}
        {/* Links Container */}
        {/* <div className={styles.linksContainer}>
                <div className={styles.link}>Integrations</div>
                <div className={styles.link}>Solutions</div>
                <div className={styles.link}>Features</div>
                <div className={styles.link}>Enterprise</div>
              </div>
            </div> */}
        {/* </div> */}

        {/* email Col Mobile*/}
        {/* <div className={styles.emailColMobile}> */}
        {/* Heading */}
        {/* <div className={styles.heading}>Get Email Notifications</div>
            <div className={styles.slogan}>
              Generate outside the box thinking with the possibility to targtet
              the low.
            </div> */}
        {/* Email Container */}
        {/* <div className={styles.emailContainer}>
              <input
                placeholder="Enter email...."
                type="text"
                className={styles.emailInput}
              />
              <div className={styles.emailSubmitButton}>Submit</div>
            </div> */}
        {/* </div> */}

        {/* divider */}
        <div className={styles.divider}></div>
        {/* bottom bar */}
        <div className={styles.bottomBar}>
          {/* copyright text */}
          <div className={styles.copyRightText}>
            © 2023 Bidd. Allur réttur áskilinn.
          </div>
          {/* icons container */}
          <div className={styles.iconsContainer}>
            <img src="/logos/facebook_logo_footer.svg" alt="facebook" />
            {/* <img src="/logos/google_logo_footer.svg" alt="google" /> */}
            {/* <img src="/logos/apple_logo_footer.svg" alt="apple" /> */}
            <img src="/logos/insta_logo_footer.svg" alt="instagram" />
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}
