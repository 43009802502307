import { useState, useEffect } from "react";
import axios from "axios";
import styles from "../../styles/BidView.module.css";
import { useNavigate } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import { useAddNewBidMutation, useUpdateBidMutation } from "./bidsApiSlice";
import {
  useUploadDocumentsMutation,
  useDeleteDocumentMutation,
} from "../documents/documentsApiSlice";
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  Typography,
  FormHelperText,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { styled } from "@mui/system";

import PrettoSlider from "../../components/PrettoSlider";
import SkillReqModal from "../../components/SkillReqModal";
import DockumentReqModal from "../../components/DocumentReqModal";
import industryList from "../../lists/industryList";
import typeList from "../../lists/typeList";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import UploadComponent from "../../components/UploadComponent";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/is";
import dayjs from "dayjs";

const FormContainer = styled("form")(({ theme }) => ({
  paddingTop: "60px",
  width: { xs: "100%", md: "60%" },
  maxWidth: "1264px",
  margin: "auto",
  padding: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

const SliderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const toolbarConfig = {
  options: [
    "inline",
    "fontSize",
    "list",
    "textAlign",
    "colorPicker",
    "link",
    "embedded",
    "remove",
    "history",
  ],
  inline: {
    options: ["bold", "italic", "underline"],
  },
  link: {
    showOpenOptionOnHover: false,
  },
};

const NewBidForm = ({ userId, bid, documents }) => {
  const navigate = useNavigate();

  const [openClosed, setOpenClosed] = useState("open");
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [industry, setIndustry] = useState("");
  const [cost, setCost] = useState("");
  const [sliderValue, setSliderValue] = useState(50);
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [formCheckedSkillsReq, setFormCheckedSkillsReq] = useState({});
  const [formCheckedDocumentsReq, setFormCheckedDocumentsReq] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [predictedStartDate, setPredictedStartDate] = useState(null);
  const [predictedEndDate, setPredictedEndDate] = useState(null);
  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [errors, setErrors] = useState({
    title: false,
    type: false,
    industry: false,
    formCheckedSkillsReq: [false],
    formCheckedDocumentsReq: [false],
    description: false,
    startDate: false,
    endDate: false,
    //dateRangeValid: false,
    predictedStartDate: false,
    predictedEndDate: false,
    //predictedDateRangeValid: false,
  });

  const [skills, setSkills] = useState([
    "Hér er stöðluð hæfniskrafa 1",
    "Hér er stöðluð hæfniskrafa 2",
    "Hér er stöðluð hæfniskrafa 3",
  ]);

  const [dockuments, setDockuments] = useState([
    "Hér er staðlað skjal 1",
    "Hér er staðlað skjal 2",
    "Hér er staðlað skjal 3",
  ]);

  useEffect(() => {
    if (bid) {
      setOpenClosed(bid.openClosed);
      setTitle(bid.title);
      setType(bid.type);
      setIndustry(bid.industry);
      setCost(formatNumber(bid.cost));
      setSliderValue(bid.skill);

      setStartDate(dayjs(bid.startDate));
      setEndDate(dayjs(bid.endDate));
      setPredictedStartDate(dayjs(bid.predictedStartDate));
      setPredictedEndDate(dayjs(bid.predictedEndDate));

      const checkedSkills = {};
      const newSkills = [];

      bid.skillRequirements.forEach((skill) => {
        checkedSkills[skill] = true;

        if (!skills.includes(skill)) {
          newSkills.push(skill);
        }
      });
      // console.log("bid", bid);
      setSkills([...skills, ...newSkills]);
      setFormCheckedSkillsReq(checkedSkills);

      const checkedDocuments = {};
      const newDocuments = [];

      bid.documentRequirements.forEach((document) => {
        checkedDocuments[document] = true;

        if (!dockuments.includes(document)) {
          newDocuments.push(document);
        }
      });
      setDockuments([...dockuments, ...newDocuments]);
      setFormCheckedDocumentsReq(checkedDocuments);

      const contentState = convertFromRaw({
        entityMap: {},
        blocks: bid.description.blocks,
      });
      const newEditorState = EditorState.createWithContent(contentState);
      setDescription(newEditorState);
    }
  }, [bid]);

  const [uploadedDocumentsList, setUploadedDocumentsList] = useState([]);

  useEffect(() => {
    if (documents?.length > 0) {
      setUploadedDocumentsList(documents);
    }
  }, [documents]);

  const formatNumber = (number) => {
    const formattedNumber = Number(number).toLocaleString("is-IS");
    return formattedNumber.replace(/,/g, ".");
  };

  const handleTitleChange = (event) => {
    const newTitle = event.target.value;
    setTitle(newTitle);
    // Clear the error when a value is added
    if (newTitle) {
      setErrors((prevErrors) => ({ ...prevErrors, title: false }));
    }
  };

  const handleTypeChange = (event) => {
    const newType = event.target.value;
    setType(newType);
    // Clear the error when a value is added
    if (newType) {
      setErrors((prevErrors) => ({ ...prevErrors, type: false }));
    }
  };

  const handleIndustryChange = (event) => {
    const newIndustry = event.target.value;
    setIndustry(newIndustry);
    // Clear the error when a value is added
    if (newIndustry) {
      setErrors((prevErrors) => ({ ...prevErrors, industry: false }));
    }
  };

  const handlePredictedCostChange = (event) => {
    // Remove non-numeric characters
    const cleanedValue = event.target.value.replace(/[^0-9]/g, "");

    // Format the cleaned value to Icelandic number format
    const formattedValue = formatNumber(cleanedValue);

    setCost(formattedValue);

    if (cleanedValue) {
      setErrors((prevErrors) => ({ ...prevErrors, cost: false }));
    }
  };

  const handleDescriptionChange = (event) => {
    const newDescription = event;
    //const contentState = ContentState.createFromText(newDescription);
    // const newEditorState = EditorState.createWithContent(contentState);
    //console.log(contentState);
    // console.log("text2: " + newEditorState)
    setDescription(newDescription);
    // Clear the error when a value is added
    if (description.getCurrentContent().hasText()) {
      setErrors((prevErrors) => ({ ...prevErrors, description: false }));
    }
  };

  const handleStartDateChange = (event) => {
    setStartDate(event);
    // Clear the error when a value is added
    if (event) {
      setErrors((prevErrors) => ({ ...prevErrors, startDate: false }));
    }
  };

  const handleEndDateChange = (event) => {
    setEndDate(event);
    // Clear the error when a value is added
    if (event) {
      setErrors((prevErrors) => ({ ...prevErrors, endDate: false }));
    }
  };

  const handlePredictedStartDateChange = (event) => {
    setPredictedStartDate(event);
    // Clear the error when a value is added
    if (event) {
      setErrors((prevErrors) => ({ ...prevErrors, predictedStartDate: false }));
    }
  };

  const handlePredictedEndDateChange = (event) => {
    setPredictedEndDate(event);
    // Clear the error when a value is added
    if (event) {
      setErrors((prevErrors) => ({ ...prevErrors, predictedEndDate: false }));
    }
  };

  const validateForm = () => {
    console.log(predictedStartDate);
    const newErrors = {
      title: title === "",
      type: type === "",
      industry: industry === "",
      cost: cost === "",
      description: description.getCurrentContent().hasText() === false,
      startDate: startDate === null,
      endDate: endDate === null,
      //dateRangeValid: endDate === null || startDate <= endDate, // Check date range validity
      predictedStartDate: predictedStartDate === null,
      predictedEndDate: predictedEndDate === null,
      //predictedDateRangeValid: predictedEndDate === null || predictedStartDate <= predictedEndDate, // Check date range validity
    };
    console.log("newErrors", newErrors);
    setErrors(newErrors);

    for (const error of Object.values(newErrors)) {
      if (error) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async () => {
    console.log("Selected files:", selectedFiles);
    const isValid = validateForm();

    if (isValid) {
      // Get the raw desciption state
      const descriptionState = description.getCurrentContent();
      const rawDescriptionState = convertToRaw(descriptionState);

      // Handle form submission
      console.log("userId", userId);
      console.log("openClosed", openClosed);
      console.log("title", title);
      console.log("description", rawDescriptionState);
      console.log("type", type);
      console.log("industry", industry);
      console.log("cost", cost);
      console.log("skill", sliderValue);
      console.log("skillRequirements", formCheckedSkillsReq);
      console.log("documentRequirements", formCheckedDocumentsReq);
      console.log("Selected files:", selectedFiles);
      console.log("Start Date:", dayjs(startDate).toDate());
      console.log("End Date:", dayjs(endDate).toDate());
      console.log("Predicted Start Date:", dayjs(predictedStartDate).toDate());
      console.log("Predicted End Date:", dayjs(predictedEndDate).toDate());

      const requiredSkills = Object.keys(formCheckedSkillsReq).filter(
        (key) => formCheckedSkillsReq[key]
      );

      const requiredDocuments = Object.keys(formCheckedDocumentsReq).filter(
        (key) => formCheckedDocumentsReq[key]
      );

      // if bid is passed in, update bid
      if (bid) {
        const { data: bidData } = await updateBid({
          id: bid.id,
          user: userId,
          openClosed,
          title,
          description: rawDescriptionState,
          type,
          industry,
          cost: Number(cost.replace(/[^0-9]/g, "")),
          skill: sliderValue,
          skillRequirements: requiredSkills,
          documentRequirements: requiredDocuments,
          startDate: dayjs(startDate).toDate(),
          endDate: dayjs(endDate).toDate(),
          predictedStartDate: dayjs(predictedStartDate).toDate(),
          predictedEndDate: dayjs(predictedEndDate).toDate(),
        });
        if (bidData) {
          const filesFormData = new FormData();
          filesFormData.append("bid", bidData?._id);

          selectedFiles.forEach((file) => {
            filesFormData.append("files", file);
          });

          // filesFormData.forEach((value, key) => {
          //   console.log(`${key}: ${value}`);
          // });

          const { isError } = await uploadDocuments(filesFormData);

          if (!isError) {
            console.log("Form submitted successfully");
            navigate("/minar-sidur");
          } else {
            console.log("Error uploading documents");
          }
        }
      } else {
        const { data: bidData } = await addNewBid({
          user: userId,
          openClosed,
          title,
          description: rawDescriptionState,
          type,
          industry,
          cost: Number(cost.replace(/[^0-9]/g, "")),
          skill: sliderValue,
          skillRequirements: requiredSkills,
          documentRequirements: requiredDocuments,
          startDate: dayjs(startDate).toDate(),
          endDate: dayjs(endDate).toDate(),
          predictedStartDate: dayjs(predictedStartDate).toDate(),
          predictedEndDate: dayjs(predictedEndDate).toDate(),
        });

        if (bidData) {
          const filesFormData = new FormData();
          filesFormData.append("bid", bidData?.bidId);

          selectedFiles.forEach((file) => {
            filesFormData.append("files", file);
          });

          // filesFormData.forEach((value, key) => {
          //   console.log(`${key}: ${value}`);
          // });

          const { isError } = await uploadDocuments(filesFormData);

          if (!isError) {
            console.log("Form submitted successfully");
            navigate("/minar-sidur");
          } else {
            console.log("Error uploading documents");
          }
        }
      }
    }
  };

  const [addNewBid, { isLoading, isSuccess, isError, error }] =
    useAddNewBidMutation();

  const [
    updateBid,
    {
      isLoading: isLoadingUpdateBid,
      isSuccess: isSuccessUpdateBid,
      isError: isErrorUpdateBid,
      error: errorUpdateBid,
    },
  ] = useUpdateBidMutation();

  const [
    uploadDocuments,
    {
      isLoading: isLoadingDoc,
      isSuccess: isSuccessDoc,
      isError: isErrorDoc,
      error: errorDoc,
    },
  ] = useUploadDocumentsMutation();

  const [
    deleteDocument,
    {
      isLoading: isLoadingDeleteDoc,
      isSuccess: isSuccessDeleteDoc,
      isError: isErrorDeleteDoc,
      error: errorDeleteDoc,
    },
  ] = useDeleteDocumentMutation();

  useEffect(() => {
    if ((isSuccess && isSuccessDoc) || isSuccessUpdateBid) {
      navigate("/minar-sidur");
    }
  }, [isSuccess, isSuccessDoc, isSuccessUpdateBid, navigate]);

  useEffect(() => {
    if (isError) {
      console.log("Error:", error);
    }
  }, [isError]);

  // if isSuccessDeleteDoc is true, reload docs from props
  useEffect(() => {
    if (isSuccessDeleteDoc) {
    }
  }, [isSuccessDeleteDoc]);

  const handleSkillFormSubmit = (checkedSkills) => {
    setFormCheckedSkillsReq(checkedSkills);
  };

  const handleReqDocumentFormSubmit = (checkedDocuments) => {
    setFormCheckedDocumentsReq(checkedDocuments);
  };

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  const downloadFile = (doc) => {
    console.log("downloading...", doc?.fileName);
    axios({
      // url: `https://bidd-backend-seven.vercel.app/documents/download/${filename}`,
      url: `${process.env.REACT_APP_BACKEND_URL}/documents/download/${doc?.fileName}`,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        // Create a download link for the file
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", doc?.documentName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  const deleteFile = async (id) => {
    console.log("deleting...");
    await deleteDocument({ id });
  };

  const content = (
    <>
      {/* <p className={errClass}>{error?.data?.message}</p> */}
      <FormContainer>
        <Typography variant="h3">Skrá nýtt útboð</Typography>
        <FormControl component="fieldset" error={errors.title}>
          <FormLabel component="legend"></FormLabel>
          <RadioGroup
            row
            value={openClosed}
            onChange={(event) => setOpenClosed(event.target.value)}
          >
            <FormControlLabel
              value="open"
              control={<Radio />}
              label="Opið útboð"
            />
            <FormControlLabel
              value="closed"
              control={<Radio />}
              label="Lokað útboð"
            />
          </RadioGroup>
        </FormControl>

        <FormControl
          sx={{ flexDirection: isMobileView ? "column" : "row" }}
          fullWidth
          error={errors.title}
        >
          <FormControl sx={{}} fullWidth error={errors.title}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="is">
              <DatePicker
                label="Útboð byrjar"
                value={startDate}
                onChange={handleStartDateChange}
                renderInput={(params) => <TextField {...params} required />}
                sx={{
                  marginRight: isMobileView ? "0px" : "5px",
                  marginBottom: isMobileView ? "16px" : "0px",
                }}
              />
            </LocalizationProvider>
            {errors.startDate && (
              <FormHelperText>
                Vinsamlegast veldu byrjunardagsetningu útboðs
              </FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth error={errors.title}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="is">
              <DatePicker
                label="Útboð lýkur"
                value={endDate}
                onChange={handleEndDateChange}
                renderInput={(params) => <TextField {...params} required />}
                sx={{ marginLeft: isMobileView ? "0px" : "5px" }}
              />
            </LocalizationProvider>
            {errors.endDate && (
              <FormHelperText>
                Vinsamlegast veldu endadagsetningu útboðs
              </FormHelperText>
            )}
            {/* {errors.dateRangeValid === false && (
            <FormHelperText>Útboð lýkur getur ekki verið á undan útboð byrjar</FormHelperText>
          )} */}
          </FormControl>
        </FormControl>
        <FormControl
          sx={{ flexDirection: isMobileView ? "column" : "row" }}
          fullWidth
          error={errors.title}
        >
          <FormControl fullWidth error={errors.title}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="is">
              <DatePicker
                label="Áætlaður byrjardagur útboðs"
                value={predictedStartDate}
                onChange={handlePredictedStartDateChange}
                renderInput={(params) => <TextField {...params} required />}
                sx={{
                  marginRight: isMobileView ? "0px" : "5px",
                  marginBottom: isMobileView ? "16px" : "0px",
                }}
              />
            </LocalizationProvider>
            {errors.predictedStartDate && (
              <FormHelperText>
                Vinsamlegast veldu áætlaða byrjunardagsetningu útboðs
              </FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth error={errors.title}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="is">
              <DatePicker
                label="Áætlaður endadagur útboðs"
                value={predictedEndDate}
                onChange={handlePredictedEndDateChange}
                renderInput={(params) => <TextField {...params} required />}
                sx={{ marginLeft: isMobileView ? "0px" : "5px" }}
              />
            </LocalizationProvider>
            {errors.predictedEndDate && (
              <FormHelperText>
                Vinsamlegast veldu áætlaða endadagsetningu útboðs
              </FormHelperText>
            )}
            {/* {errors.predictedDateRangeValid === false && (
            <FormHelperText>Útboð lýkur getur ekki verið á undan útboð byrjar</FormHelperText>
          )} */}
          </FormControl>
        </FormControl>

        <FormControl fullWidth error={errors.title}>
          <TextField
            label="Titill"
            value={title}
            onChange={handleTitleChange}
            fullWidth
            required
          />
          {errors.title && (
            <FormHelperText>Vinsamlegast skráðu titil</FormHelperText>
          )}
        </FormControl>
        <FormControl fullWidth error={errors.type}>
          <InputLabel required>Tegund</InputLabel>
          <Select
            value={type}
            label="Tegund"
            onChange={handleTypeChange}
            required
          >
            {typeList.map((value, index) => (
              <MenuItem key={index} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
          {errors.type && (
            <FormHelperText>Vinsamlegast veldu tegund</FormHelperText>
          )}
        </FormControl>
        <FormControl fullWidth error={errors.industry}>
          <InputLabel required>Atvinnugrein</InputLabel>
          <Select
            value={industry}
            label="Atvinnugrein"
            onChange={handleIndustryChange}
            required
          >
            {industryList.map((value, index) => (
              <MenuItem key={index} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
          {errors.industry && (
            <FormHelperText>Vinsamlegast veldu atvinnugrein</FormHelperText>
          )}
        </FormControl>
        <FormControl fullWidth error={errors.cost}>
          <TextField
            label="Áætlaður kostnaður"
            type="text"
            value={cost}
            onChange={handlePredictedCostChange}
            fullWidth
            required
          />
          {errors.cost && (
            <FormHelperText>
              Vinsamlegast skráðu áætlaðan kostnað
            </FormHelperText>
          )}
        </FormControl>
        <FormControl>
          <SkillReqModal
            setSkills={setSkills}
            skills={skills}
            onSubmit={handleSkillFormSubmit}
            formCheckedSkillsReq={formCheckedSkillsReq}
          />
        </FormControl>
        <FormControl fullWidth>
          <DockumentReqModal
            setDockuments={setDockuments}
            dockuments={dockuments}
            onSubmit={handleReqDocumentFormSubmit}
            formCheckedDocumentsReq={formCheckedDocumentsReq}
          />
        </FormControl>
        <FormControl>
          <SliderContainer>
            <Typography sx={{ width: "120px" }}>
              Hæfni {sliderValue}%
            </Typography>
            <PrettoSlider
              value={sliderValue}
              onChange={handleSliderChange}
              valueLabelDisplay="on"
              aria-labelledby="discrete-slider"
              step={1}
              size="large"
              min={0}
              max={100}
            />
            <Typography sx={{ width: "120px", textAlign: "right" }}>
              {100 - sliderValue}% Verð{" "}
            </Typography>
          </SliderContainer>
        </FormControl>
        <FormControl fullWidth error={errors.description}>
          <Editor
            toolbar={toolbarConfig}
            editorState={description}
            // description={description}
            onEditorStateChange={handleDescriptionChange}
            wrapperClassName="editor-wrapper"
            editorClassName="editor-content"
            placeholder="Lýsing á útboði.."
          />
          {errors.description && (
            <FormHelperText>Vinsamlegast skráðu lýsingu</FormHelperText>
          )}
        </FormControl>

        {uploadedDocumentsList?.length > 0 && (
          <div className={styles.documents}>
            <div className={styles.documentsTitle}>Skráð skjöl</div>

            {/* docs Grid */}
            <div className={styles.documentsGrid}>
              {uploadedDocumentsList.map((document) => {
                // let docLink = `https://bidd-backend-seven.vercel.app/documents/${document?.fileName}`;
                let docLink = `${process.env.REACT_APP_BACKEND_URL}/documents/${document?.fileName}`;
                return (
                  <div
                    className={styles.documentContainer}
                    key={document.id}
                    onClick={(e) => {
                      if (
                        document.fileMimetype.startsWith("image") ||
                        document.fileMimetype === "application/pdf" ||
                        document.fileMimetype === "text/plain"
                      ) {
                        return (window.location.href = docLink);
                      } else {
                        return downloadFile(document);
                      }
                    }}
                  >
                    {/* thubnail container */}
                    <div className={styles.thumbnailContainer}>
                      <div className={styles.downloadRow}>
                        <img
                          src="/icons/bidd/download.svg"
                          alt="download icon"
                          className={styles.downloadIcon}
                          onClick={(e) => {
                            e.stopPropagation();
                            downloadFile(document);
                          }}
                        />
                      </div>

                      <img
                        className={
                          document.fileMimetype.startsWith("image")
                            ? styles.documentIcon
                            : null
                        }
                        src={
                          document.fileMimetype === "image/jpeg" ||
                          document.fileMimetype === "image/jpg" ||
                          document.fileMimetype === "image/png" ||
                          document.fileMimetype === "image/bmp" ||
                          document.fileMimetype === "image/gif" ||
                          document.fileMimetype === "image/svg+xml"
                            ? docLink
                            : document.fileMimetype === "application/pdf"
                            ? "/icons/bidd/pdfFile.svg"
                            : document.fileMimetype ===
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                              document.fileMimetype === "application/msword" ||
                              document.fileMimetype === "text/plain"
                            ? "/icons/bidd/wordFile.svg"
                            : document.fileMimetype ===
                                "application/vnd.ms-excel" ||
                              document.fileMimetype ===
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            ? "/icons/bidd/excelFile.svg"
                            : null
                        }
                        alt="document icon"
                      />
                    </div>
                    {/* name */}
                    <div className={styles.documentName}>
                      {document.documentName}
                    </div>

                    {isLoadingDeleteDoc ? (
                      <PulseLoader color={"#FFF"} />
                    ) : (
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: red[500],
                          color: "white",
                          width: "80%",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteFile(document._id);
                        }}
                      >
                        Eyða
                      </Button>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <FormControl>
          <UploadComponent onFilesSelected={setSelectedFiles} />
        </FormControl>
        <div>
          <Button
            sx={{ float: "right" }}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Skrá útboð
          </Button>
        </div>
      </FormContainer>
    </>
  );

  return content;
};

export default NewBidForm;
