import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const bidsAdapter = createEntityAdapter({ 
    sortComparer: (a, b) => (a.completed === b.completed) ? 0 : a.completed ? 1 : -1
})

const initialState = bidsAdapter.getInitialState()

export const bidsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getBids: builder.query({
            query: () => ({
                url: '/bids',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedBids = responseData.map(bid => {
                    bid.id = bid._id
                    return bid
                });
                return bidsAdapter.setAll(initialState, loadedBids)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Bid', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Bid', id }))
                    ]
                } else return [{ type: 'Bid', id: 'LIST' }]
            }
        }),
        addNewBid: builder.mutation({
            query: initialBid => ({
                url: '/bids',
                method: 'POST',
                body: {
                    ...initialBid,
                }
            }),
            invalidatesTags: [
                { type: 'Bid', id: "LIST" }
            ]
        }),
        updateBid: builder.mutation({
            query: initialBid => ({
                url: '/bids',
                method: 'PATCH',
                body: {
                    ...initialBid,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Bid', id: arg.id }
            ]
        }),
        deleteBid: builder.mutation({
            query: ({ id }) => ({
                url: `/bids`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Bid', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetBidsQuery,
    useAddNewBidMutation,
    useUpdateBidMutation,
    useDeleteBidMutation,
} = bidsApiSlice

// returns the query result object
export const selectBidsResult = bidsApiSlice.endpoints.getBids.select()

// creates memoized selector
const selectBidsData = createSelector(
    selectBidsResult,
    bidsResult => bidsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllBids,
    selectById: selectBidById,
    selectIds: selectBidIds
    // Pass in a selector that returns the bids slice of state 
} = bidsAdapter.getSelectors(state => selectBidsData(state) ?? initialState)