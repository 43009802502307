import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "../styles/DropDownLoggedInHeader.module.css";
import styled, { keyframes, css } from "styled-components";
import { useSendLogoutMutation } from "../features/auth/authApiSlice";

const menuOptions = [
  {
    title: "Prófíll",
    icon_src: "/icons/bidd/profile_icon_dropdown.svg",
  },
  {
    title: "Skilaboð",
    icon_src: "/icons/bidd/message_icon_dropdown.svg",
  },
  {
    title: "Stillingar",
    icon_src: "/icons/bidd/settings_icon_dropdown.svg",
  },
  // {
  //   title: "Mín útboð",
  //   icon_src: "/icons/bidd/history_icon_dropdown.svg",
  // },
  {
    title: "Útskrá",
    icon_src: "/icons/bidd/logout_icon_dropdown.svg",
  },
];

let scaleZ = keyframes`{
    0% {
        opacity: 0;
        transform: scale(0);
    }

    80% {
        transform: scale(1.07);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}`;

const DropDownItem = styled.div`
  display: flex;
  height: 40px;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 100%;
  border-radius: 5px;

  opacity: 0;
  cursor: pointer;

  &:hover {
    background: #f9f1f1;
  }

  ${(props) =>
    props.active &&
    css`
      background: #c2ffed;
    `}

  transform-origin: top center;

  ${(props) =>
    props.index &&
    css`
      animation: ${scaleZ} 300ms ${60 * props.index}ms ease-in-out forwards;
    `}
`;

export default function DropDownLoggedInHeader() {
  const navigate = useNavigate();

  const [sendLogout, { isLoading, isSuccess, isError, error }] =
    useSendLogoutMutation();

  useEffect(() => {
    if (isSuccess) navigate("/");
  }, [isSuccess, navigate]);

  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [option, setOption] = useState({
    name: "Excellent",
    stars: "RatingStarsExcellent",
  });

  return (
    <>
      <div
        className={styles.userReviewRatingDropDown}
        onClick={() => setToggleDropdown(!toggleDropdown)}
      >
        {/* avatar */}
        <img
          src="/images/avatar_header.svg"
          alt="avatar"
          //   style={{ width: "12px", height: "6px" }}
          className={`${styles.ReviewRatingDropDownArrow} ${
            toggleDropdown == true && styles.selectedArrow
          }`}
        />
      </div>

      {toggleDropdown && (
        <div className={styles.dropdownRatingMenu}>
          <div className={styles.itemsContainer}>
            {menuOptions.map((menuOption, index) => (
              <DropDownItem
                key={index}
                index={index + 1}
                onClick={() => {
                  setToggleDropdown(!toggleDropdown);

                  if (menuOption.title === "Útskrá") {
                    sendLogout();
                  } else if (menuOption.title === "Prófíll") {
                    navigate("/minar-sidur/profile");
                  }
                  // setOption(menuOption);
                }}
                //   active={menuOption.name === option.name}
              >
                {/* item Icon */}
                <img src={menuOption.icon_src} alt="icon" />
                {/* item title */}
                <div className={styles.itemTitle}>{menuOption.title}</div>
              </DropDownItem>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
