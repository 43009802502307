import { createTheme } from "@mui/material";

const lexendFont = {
  fontFamily: 'Lexend, Arial, sans-serif'
};


const theme = createTheme({
  palette: {
    primary: {
      main: '#78CA72',
    },
    secondary: {
      main: '#D3FAD9',
    },    
    hover: {
      main:'#548D4F'
    }
  },
  typography: {
    fontFamily: 'Lexend, Arial, sans-serif', // Set the default font for the entire app
    button: {
      textTransform: 'none'
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [lexendFont],
      },
    },
  },  
});

export default theme;