import React from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  ListItemIcon,
  CardMedia,
  FormHelperText,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const UploadComponent = ({ onFilesSelected }) => {
  const [isDragActive, setIsDragActive] = React.useState(false);
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [showFileTypeErorr, setShowFileTypeError] = React.useState(false);

  const onDrop = (acceptedFiles) => {
    //const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
    // const validFiles = acceptedFiles.filter(
    //   (file) =>
    //     file.size <= maxSizeInBytes && // Validate file size
    //     ['image/jpeg', 'image/png', 'application/pdf'].includes(file.type) // Validate file types
    // );

    // check files for valid file types

    setShowFileTypeError(false);

    const validFiles = acceptedFiles.filter(
      (file) =>
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "image/bmp" ||
        file.type === "image/gif" ||
        file.type === "image/svg+xml" ||
        file.type === "application/pdf" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type === "application/msword" ||
        file.type === "text/plain" ||
        file.type === "application/vnd.ms-excel" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );

    //  if invalid files, show error message
    if (acceptedFiles.length !== validFiles.length) {
      setShowFileTypeError(true);
    }

    const newSelectedFiles = [...selectedFiles, ...validFiles];
    setSelectedFiles(newSelectedFiles);
    if (onFilesSelected) {
      onFilesSelected(newSelectedFiles);
    }
  };

  const removeFile = (fileToRemove) => {
    const updatedFiles = selectedFiles.filter((file) => file !== fileToRemove);
    setSelectedFiles(updatedFiles);
    setShowFileTypeError(false);
    if (onFilesSelected) {
      onFilesSelected(updatedFiles);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDragEnter: () => setIsDragActive(true),
    onDragLeave: () => setIsDragActive(false),
    onDropAccepted: () => setIsDragActive(false),
  });

  return (
    <Box>
      <Grid item xs={12}>
        <Typography variant="h6">Skjöl</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box
          border={isDragActive ? "2px dashed #666" : "2px dashed #ccc"}
          padding={10}
          textAlign="center"
          backgroundColor={isDragActive ? "#D3FAD9" : "transparent"}
          {...getRootProps()}
          sx={{
            cursor: "pointer",
            borderRadius: "4px",
          }}
        >
          <input {...getInputProps()} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start", // This sets the horizontal alignment to start
            }}
          >
            <ListItemIcon sx={{ fontSize: 32 }}>
              <UploadFileIcon fontSize="large" />
            </ListItemIcon>
            <Box
              sx={{
                display: "flex",
                alignItems: "start",
                justifyContent: "start",
                flexDirection: "column", // This sets the horizontal alignment to start
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeigth: "bold",
                }}
              >
                Dragið skrár hingað inn eða ýtið hér til að hlaða upp skrám
              </Typography>

              <Typography variant="body1" sx={{ color: "#666" }}>
                {/* show text for allowed files png, jpg, jpeg, gif, bmp, doc, docx, txt, pdf, xls, xlsx,  */}
                Leyfilegar skrár eru: png, jpg, jpeg, gif, bmp, doc, docx, txt,
                xls, xlsx og pdf
              </Typography>
            </Box>
          </Box>
        </Box>

        {showFileTypeErorr && (
          <FormHelperText sx={{ color: "red", marginTop: "10px" }}>
            {" "}
            Ekki leyfileg skráar ending
          </FormHelperText>

          // <Typography variant="body1" sx={{ color: "red", marginTop: "10px" }}>
          //   Ekki leyfileg skrár ending
          // </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        {/* Display uploaded files */}
        {selectedFiles.map((file, index) => (
          <Box
            key={index}
            border="1px solid #ccc"
            borderRadius="4px"
            p={1}
            mt={1}
            display="flex"
            alignItems="center"
          >
            <ListItemIcon sx={{ fontSize: 32 }}>
              {/* <UploadFileIcon fontSize="large" />
               */}

              <CardMedia
                component="img"
                height="100"
                sx={{
                  objectFit: "cover",
                  padding: "10px",
                }}
                image={
                  file.type === "image/jpeg" ||
                  file.type === "image/jpg" ||
                  file.type === "image/png" ||
                  file.type === "image/svg+xml" ||
                  file.type === "image/bmp" ||
                  file.type === "image/gif"
                    ? URL.createObjectURL(file)
                    : file.type === "application/pdf"
                    ? "/icons/bidd/pdfFile.svg"
                    : file.type ===
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                      file.type === "application/msword" ||
                      file.type === "text/plain"
                    ? "/icons/bidd/wordFile.svg"
                    : file.type === "application/vnd.ms-excel" ||
                      file.type ===
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ? "/icons/bidd/excelFile.svg"
                    : null
                }
                alt="Skrá"
              />
            </ListItemIcon>
            <Typography>{file.name}</Typography>
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={() => removeFile(file)}
              sx={{ marginLeft: "auto" }}
            >
              Eyða
            </Button>
          </Box>
        ))}
      </Grid>
    </Box>
  );
};

export default UploadComponent;
