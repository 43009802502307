import React from "react";
import "./InputForm.css";

const InputForm = () => {
  return (
    <div id="Dokobit-identity-container">
      <div id="dokobit-identity">
        <div className="dokobit-main-logo">
          <a href=""></a>
        </div>
        <div className="dokobit-main dokobit-main--methods d-none">
          <div className="dokobit-main__header">
            <h3>Select login method</h3>
          </div>
          <div className="flex column full-width mt mb-8"></div>
          <ul className="d-methods-list">
            <li className="d-methods-list__method" data-tab-show="mobile-tab">
              <label>
                <span>Mobile ID</span>
                <svg
                  className="d-methods-list__method__icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                >
                  <path d="M16 1H8C6.34 1 5 2.34 5 4v16c0 1.66 1.34 3 3 3h8c1.66 0 3-1.34 3-3V4c0-1.66-1.34-3-3-3zm-2 20h-4v-1h4v1zm3.25-3H6.75V4h10.5v14z"></path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </label>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                className="identity-arrow"
              >
                <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path>
                <path fill="none" d="M0 0h24v24H0V0z"></path>
              </svg>
            </li>
          </ul>
        </div>
        <div
          id="mobile"
          className="dokobit-main dokobit-main--method d-block"
          data-tab-content="mobile-tab"
        >
          <div className="dokobit-main__back">
            <h3>Log in using Mobile ID</h3>
            <svg
              className="d-methods-list__method__icon"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
            >
              <path d="M16 1H8C6.34 1 5 2.34 5 4v16c0 1.66 1.34 3 3 3h8c1.66 0 3-1.34 3-3V4c0-1.66-1.34-3-3-3zm-2 20h-4v-1h4v1zm3.25-3H6.75V4h10.5v14z"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
          </div>
          <form
            className="identity-form"
            name="mobile_login"
            // action="/api/authentication/27eada536cf9bc55157c11f11d1037e70798930bbe2dd46bc799a1a382dbe47a/mobile"
            noValidate=""
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="d-form-group">
              <div className="d-input-group">
                <ul className="select-document select-user-country">
                  <li className="active">
                    <span className="select-document__prefix">+354</span>
                    <span className="select-document__text">Iceland</span>
                    <img
                      className="select-flag"
                      src="https://id-sandbox.dokobit.com/assets/img/flags/is.svg"
                    />
                  </li>
                </ul>
                <div className="selected-document disabled">
                  <div className="selected-document__box">
                    <img
                      className="flag"
                      src="https://id-sandbox.dokobit.com/assets/img/flags/is.svg"
                    />
                  </div>
                </div>
                <input
                  type="tel"
                  id="phoneMobileid"
                  name="phoneMobileid"
                  required=""
                  autoComplete="new-phone"
                  inputMode="tel"
                  defaultValue="+354"
                />
                <label htmlFor="phoneMobileid" className="required">
                  Símanúmer
                </label>
              </div>
            </div>
            <div className="dokobit-main-links">
              <button
                className="dokobit-main-links__item is-reversed dokobit-main-btn--bubble-fx"
                type="submit"
                name="button"
                onClick={() => {}}
              >
                Innskrá
              </button>
            </div>
          </form>
        </div>
        <div
          id="identityConfirmation"
          className="dokobit-main dokobit-main--method dokobit-main--control-code d-none"
        >
          <div id="dinamic-verification-title">
            <div className="dokobit-main__back">
              <h3>Log in using Mobile ID</h3>
              <svg
                className="d-methods-list__method__icon"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
              >
                <path d="M16 1H8C6.34 1 5 2.34 5 4v16c0 1.66 1.34 3 3 3h8c1.66 0 3-1.34 3-3V4c0-1.66-1.34-3-3-3zm-2 20h-4v-1h4v1zm3.25-3H6.75V4h10.5v14z"></path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
            </div>
          </div>
          <div className="d-control-code">
            <span className="d-control-code__title">
              Your verification code is:
            </span>
            <div className="d-control-code__code" id="controlCode">
              ...
            </div>
            <div className="spinner-container">
              <div id="dokobit-spinner"></div>
              <div className="d-control-code__time">
                <span className="controlCodeCounter">180</span>
                <span>s</span>
              </div>
            </div>
          </div>
          <p className="d-control-code-description">
            If the code above matches the one you see on your phone screen,
            please verify your identity by entering your sPIN code.
          </p>
          <div className="button-container">
            <button className="dokobit-main-btn dokobit-main-btn--inverse dokobit-main-btn--cancel dokobit-main-btn--bubble-fx">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InputForm;
