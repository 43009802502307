import React, { useState, useEffect } from 'react';
import { Zoom } from '@mui/material';
import styles from "../styles/Home.module.css";

const words = ['forritara?', 
               'tölvubúnað?', 
               'múrara?', 'ræstingar?', 
               'málara?', 'smið?'];

const TextZoom = () => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [zoomIn, setZoomIn] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      // Toggle zoom direction (zoom in or zoom out)
      setZoomIn((prevZoomIn) => !prevZoomIn);

      // Move to the next word if zooming out (going from fully visible to hidden)
      if (!zoomIn) {
        setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
      }
    }, 1000); // Change zoom direction every 1 second

    return () => {
      // Cleanup interval when the component is unmounted
      clearInterval(interval);
    };
  }, [zoomIn]);

  return (
    <div>
      <h1 style={{ display: 'flex', alignItems: 'center', fontSize: '52px' }}>
        <span className={styles.heroWordVantar}>Vantar</span>
        <div style={{ position: 'relative', marginLeft: '10px' }}>
          {words.map((word, index) => (
            <Zoom in={index === currentWordIndex && zoomIn} key={index}>
              <h1 style={{ color: '#78ca72', fontSize: '52px', position: 'absolute', top: -30, left: 5 }}>
                <span className={styles.heroWords}>{word}</span>
              </h1>
            </Zoom>
          ))}
        </div>
      </h1>
    </div>
  );
};

export default TextZoom;
