import { useState } from "react";
import styles from "../styles/HeaderDropDown.module.css";
import styled, { keyframes, css } from "styled-components";

const menuOptions = [
  {
    title: "Academy",
    description:
      "No matter what kind of home you have to share, you can increase your earnings.",
  },
  {
    title: "Resources",
    description:
      "No matter what kind of home you have to share, you can increase your earnings.",
  },
  {
    title: "Guides",
    description:
      "No matter what kind of home you have to share, you can increase your earnings.",
  },
  {
    title: "Design",
    description:
      "No matter what kind of home you have to share, you can increase your earnings.",
  },
];

let scaleZ = keyframes`{
    0% {
        opacity: 0;
        transform: scale(0);
    }

    80% {
        transform: scale(1.07);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}`;

const DropDownItem = styled.div`
  display: flex;
  padding: 10px;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 5px;

  opacity: 0;
  cursor: pointer;

  &:hover {
    background: #272d37;
  }

  ${(props) =>
    props.active &&
    css`
      background: #c2ffed;
    `}

  transform-origin: top center;

  ${(props) =>
    props.index &&
    css`
      animation: ${scaleZ} 300ms ${60 * props.index}ms ease-in-out forwards;
    `}
`;

export default function HeaderDropDown() {
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [option, setOption] = useState({
    name: "Excellent",
    stars: "RatingStarsExcellent",
  });

  return (
    <>
      <div
        className={styles.userReviewRatingDropDown}
        onClick={() => setToggleDropdown(!toggleDropdown)}
      >
        <div
          className={`${styles.link} ${
            toggleDropdown == false && styles.selected
          }`}
        >
          Fyrir hvern er Bidd
        </div>
        <img
          src="/icons/bidd/chevron_down.svg"
          alt="dropDownArrow"
          //   style={{ width: "12px", height: "6px" }}
          className={`${styles.ReviewRatingDropDownArrow} ${
            toggleDropdown == true && styles.selectedArrow
          }`}
        />
      </div>

      {toggleDropdown && (
        <div className={styles.dropdownRatingMenu}>
          <div className={styles.itemsContainer}>
            {menuOptions.map((option, index) => (
              <DropDownItem
                key={index}
                index={index + 1}
                onClick={() => {
                  setToggleDropdown(!toggleDropdown);
                  // setOption(option);
                }}
                //   active={option.name === option.name}
              >
                {/* item Icon */}
                <img
                  src={`/icons/bidd/${option.title.toLocaleLowerCase()}_icon.svg`}
                  alt={option.title.toLocaleLowerCase() + "icon"}
                />
                {/* details Container */}
                <div className={styles.detailsContainer}>
                  {/* item title */}
                  <div className={styles.itemTitle}>{option.title}</div>
                  {/* item description */}
                  <div className={styles.itemDescription}>
                    {option.description}
                  </div>
                </div>
              </DropDownItem>
            ))}
          </div>
          {/* CTA section */}
          <div className={styles.ctaSection}>
            {/* contact us btn */}
            <div className={styles.contactUsBtn}>Contact Us</div>
            {/* info btn  */}
            <div className={styles.infoBtn}>Info</div>
          </div>
        </div>
      )}
    </>
  );
}
