import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "../styles/FilterDropDown.module.css";
import styled, { keyframes, css } from "styled-components";

let scaleZ = keyframes`{
    0% {
        opacity: 0;
        transform: scale(0);
    }

    80% {
        transform: scale(1.07);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}`;

const DropDownItem = styled.div`
  display: flex;
  width: 100%;
  min-height: 48px;
  padding: 0px 10px 0px 20px;
  align-items: center;
  gap: 8px;
  color: var(--gray-50, #5f6d7e);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
  border-radius: 60px;
  background: var(--white, #fff);

  opacity: 0;
  cursor: pointer;

  &:hover {
    background: #f9f1f1;
  }

  ${(props) =>
    props.active &&
    css`
      border: 1px solid black;
    `}

  transform-origin: top center;

  ${(props) =>
    props.index &&
    css`
      animation: ${scaleZ} 300ms ${60 * props.index}ms ease-in-out forwards;
    `}
`;

const DropDownItemSize = styled.div`
  display: flex;
  width: 176px;
  // height: 48px;
  padding: 0px 5px 0px 10px;
  align-items: center;
  gap: 8px;
  color: var(--gray-50, #5f6d7e);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
  border-radius: 10px;
  background: var(--white, #fff);

  opacity: 0;
  cursor: pointer;

  // &:hover {
  //   background: #ffffff;
  // }

  ${(props) =>
    props.active &&
    css`
      border: 1px solid black;
    `}

  transform-origin: top center;

  ${(props) =>
    props.index &&
    css`
      animation: ${scaleZ} 300ms ${60 * props.index}ms ease-in-out forwards;
    `}
`;

export default function FilterDropDown({ title, options, setFilterOptions }) {
  const [toggleDropdown, setToggleDropdown] = useState(false);

  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");
  const [inputError, setInputError] = useState("");

  const formatNumber = (number) => {
    // Remove all non-digit characters
    const cleanedNumber = number.replace(/\D/g, "");

    // Use regex to insert dots after every three digits
    const formattedNumber = cleanedNumber.replace(/(\d)(?=(\d{3})+$)/g, "$1.");

    return formattedNumber;
  };

  const changeCheck = (optionName) => {
    let newOptions = options?.map((option) => {
      if (option.name === optionName) {
        return { ...option, check: !option.check };
      } else {
        return option;
      }
    });

    setFilterOptions((prev) => {
      return { ...prev, [title]: newOptions };
    });
  };

  const updateFilteredBids = (from, to) => {
    if (from === "" && to === "") {
      let newOptions = options?.map((option) => {
        if (option.name === "Range") {
          return { ...option, From: null, To: null, check: false };
        } else {
          return option;
        }
      });

      setFilterOptions((prev) => {
        return { ...prev, [title]: newOptions };
      });
      setInputError("");
      return;
    }

    // Remove non-numeric characters
    const cleanedFrom = from.replace(/[^0-9]/g, "");
    const cleanedTo = to.replace(/[^0-9]/g, "");

    // console.log("cleanedFrom", cleanedFrom);
    // console.log("cleanedTo", cleanedTo);

    if (cleanedFrom !== "" && cleanedTo !== "") {
      if (Number(cleanedFrom) > Number(cleanedTo)) {
        setInputError('"Frá" getur ekki verið hærra en "Til".');

        let newOptions = options?.map((option) => {
          if (option.name === "Range") {
            return { ...option, From: null, To: null, check: false };
          } else {
            return option;
          }
        });

        setFilterOptions((prev) => {
          return { ...prev, [title]: newOptions };
        });

        return;
      }
    }

    setInputError("");

    let newOptions = options?.map((option) => {
      if (option.name === "Range") {
        return {
          ...option,
          From: cleanedFrom === "" ? 0 : Number(cleanedFrom),
          To: cleanedTo === "" ? Infinity : Number(cleanedTo),
          check: true,
        };
      } else {
        return option;
      }
    });

    setFilterOptions((prev) => {
      return { ...prev, [title]: newOptions };
    });
  };

  const handleFromInputChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatNumber(inputValue); // Call the formatNumber function
    setFromValue(formattedValue);
    updateFilteredBids(formattedValue, toValue);
  };

  const handleToInputChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatNumber(inputValue); // Call the formatNumber function
    setToValue(formattedValue);
    updateFilteredBids(fromValue, formattedValue);
  };

  return (
    <>
      <div
        className={styles.userReviewRatingDropDown}
        onClick={() => setToggleDropdown(!toggleDropdown)}
        key={title}
      >
        {/* text */}
        <p>{title}</p>
        <img
          src="/icons/bidd/chevron_up_sidebar.svg"
          alt="chevron_down"
          className={`${styles.ReviewRatingDropDownArrow} ${
            toggleDropdown == true && styles.selectedArrow
          }`}
        />
      </div>

      {toggleDropdown && (
        <div className={styles.dropdownRatingMenu}>
          {options?.map((menuOption, index) => {
            if (menuOption.name != "Range")
              return (
                <DropDownItem
                  key={index}
                  index={index + 1}
                  onClick={() => changeCheck(menuOption.name)}
                  active={menuOption.check}
                >
                  <div
                    className={
                      menuOption.check
                        ? styles.checkBoxChecked
                        : styles.checkBox
                    }
                    key={index}
                  >
                    {menuOption.check && (
                      <img
                        src="/icons/bidd/check_icon_sidebar.svg"
                        alt="check"
                      />
                    )}
                  </div>
                  <p>{menuOption.name}</p>
                </DropDownItem>
              );
            else {
              return (
                <div
                  key={0}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <DropDownItemSize key={1} index={1}>
                    <input
                      type="text"
                      value={fromValue}
                      onChange={handleFromInputChange}
                      placeholder="Frá"
                      className={styles.filterInput}
                    />
                  </DropDownItemSize>
                  <DropDownItemSize key={2} index={2}>
                    <input
                      type="text"
                      value={toValue}
                      onChange={handleToInputChange}
                      placeholder="Til"
                      className={styles.filterInput}
                    />
                  </DropDownItemSize>

                  {inputError && (
                    <DropDownItemSize key={3} index={3}>
                      <div className={styles.error}>{inputError}</div>
                    </DropDownItemSize>
                  )}
                </div>
              );
            }
          })}
        </div>
      )}
    </>
  );
}
