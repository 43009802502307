import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useLoginMutation } from "../features/auth/authApiSlice";

import { useNavigate } from "react-router-dom";
import { selectCurrentToken, setCredentials } from "../features/auth/authSlice";
import axios from "axios";
import PulseLoader from "react-spinners/PulseLoader";
import styles from "../styles/Authorize.module.css";

const Authorize = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userRole = localStorage.getItem("userRole")
    ? localStorage.getItem("userRole")
    : null;

  const token = useSelector(selectCurrentToken);

  const [authorizing, setAuthorizing] = useState(false);

  const [authUserInfo, setAuthUserInfo] = useState(null);

  useEffect(() => {
    if (token != null) {
      window.history.replaceState({}, document.title, "/authorize");
      navigate("/minar-sidur");
    }
  }, [navigate, token]);

  const [login, { isLoading: isLoadingLogin }] = useLoginMutation();

  useEffect(() => {
    // Get the current URL
    const url = new URL(window.location.href);

    if (url.searchParams.has("session_token")) {
      // Get the value of the 'session_token' query parameter
      const sessionToken = url.searchParams.get("session_token");

      // Call the authorizeUser function
      authorizeUser(sessionToken);
    }

    // setAuthUserInfo({
    //   phone: "12345678",
    //   name: "new name",
    //   surname: "new surname",
    //   // company: "manager-company",
    // });

    // setAuthUserInfo({
    //   phone: "55555555",
    //   name: "employee-name",
    //   surname: "employee",
    //   company: "company-name",
    // });
  }, [window.location.href]);

  const authorizeUser = (session_token) => {
    //set loader
    setAuthorizing(true);

    // Replace 'YOUR_BACKEND_API_URL' with the actual URL of your backend API
    // const backendUrl = "https://bidd-backend-seven.vercel.app";
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    console.log("returnToken", session_token);

    axios
      .get(`${backendUrl}/auth/${session_token}/status`)
      .then((response) => {
        if (response.data) {
          console.log("response.data", response.data);
          setAuthUserInfo(response.data);
        } else {
          console.log("Authorization failed:", response.data);
        }
        setAuthorizing(false);
      })
      .catch((error) => {
        console.error("Authentication error:", error.message);
        setAuthorizing(false);
      });
  };

  useEffect(() => {
    // set the filed values from auth user
    if (authUserInfo != null) {
      handleLogin();
    }
  }, [authUserInfo]);

  const handleLogin = async () => {
    console.log("logging in...");

    const userObject =
      userRole == null
        ? {
            phone: authUserInfo?.phone,
            name: authUserInfo?.name,
            surname: authUserInfo?.surname,
            // company: authUserInfo?.company,
          }
        : {
            phone: authUserInfo?.phone,
            roles: [userRole],
            name: authUserInfo?.name,
            surname: authUserInfo?.surname,
            // company: authUserInfo?.company,
          };

    try {
      const { accessToken } = await login(userObject).unwrap();
      console.log("accessToken", accessToken);
      dispatch(setCredentials({ accessToken }));
    } catch (err) {
      console.log("err", err);
      if (!err.status) {
        console.log("No Server Response");
      } else if (err.status === 400) {
        console.log("Missing Phone Number");
      } else if (err.status === 401) {
        console.log("Unauthorized");
      } else {
        console.log(err.data?.message);
      }
    }
  };

  return (
    authorizing && (
      <div className={styles.authorizationContainer}>
        <p className={styles.title}>Auðkenni</p>
        <PulseLoader color={"#FFF"} />
      </div>
    )
  );
};

export default Authorize;
