import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Box,
  FormControlLabel,
  Checkbox,
  IconButton,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const SkillReqModal = ({
  onSubmit,
  formCheckedSkillsReq,
  skills,
  setSkills,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [newSkill, setNewSkill] = useState("");
  const [checkedSkills, setCheckedSkills] = useState({});

  useEffect(() => {
    setCheckedSkills({ ...formCheckedSkillsReq });
  }, [formCheckedSkillsReq]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleAddSkill = () => {
    if (newSkill) {
      setSkills([...skills, newSkill]);
      setCheckedSkills({ ...checkedSkills, [newSkill]: true });
      setNewSkill("");
    }
  };

  const handleToggleSkill = (skill) => {
    setCheckedSkills((prevCheckedSkills) => ({
      ...prevCheckedSkills,
      [skill]: !prevCheckedSkills[skill],
    }));
  };

  const handleComplete = () => {
    onSubmit(checkedSkills); // Pass checkedSkills to the parent form
    handleCloseModal();
  };

  return (
    <div>
      {/* Your other form fields */}
      <Button variant="contained" onClick={handleOpenModal}>
        Skrá hæfniskröfur
      </Button>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "4px",
          }}
        >
          <h2>Hæfniskröfur</h2>
          {skills?.map((skill, index) => {
            if (skill === "" || skill === undefined || skill === null) return;
            return (
              <div key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedSkills[skill] || false}
                      onChange={() => handleToggleSkill(skill)}
                    />
                  }
                  label={skill}
                />
              </div>
            );
          })}
          <TextField
            value={newSkill}
            onChange={(e) => setNewSkill(e.target.value)}
            label="Ný hæfniskrafa.."
            variant="outlined"
            fullWidth
          />
          <IconButton onClick={handleAddSkill} sx={{ color: "#78ca72" }}>
            <AddIcon />
            <Box sx={{ color: "#78ca72" }}>Skrá nýja hæfniskröfu</Box>
          </IconButton>
          <br />
          <Box sx={{ marginTop: "20px", float: "right" }}>
            <Button variant="contained" onClick={handleComplete}>
              Loka
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default SkillReqModal;
