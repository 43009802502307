import React, { useState } from "react";
import styles from "../styles/HeaderLoggedIn.module.css";
import { useNavigate } from "react-router-dom";
import DropDownLoggedInHeader from "./DropDownLoggedInHeader";
import MenuModalLoggedIn from "./MenuModalLoggedIn";
import useAuth from "../hooks/useAuth";
import { ROLES } from "../config/roles";

export default function HeaderLoggedIn() {
  const navigate = useNavigate();
  const { roles } = useAuth();

  //const employeeRole = roles.some((role) => [ROLES.Employee].includes(role));

  const isAdmin = roles.some((role) => [ROLES.Admin].includes(role));

  const [toggleHamburgerMenuModal, setToggleHamburgerMenuModal] =
    useState(false);

  return (
    <div className={styles.navBar}>
      <div className={styles.navContent}>
        <div className={styles.logo} onClick={() => navigate("/minar-sidur")}>
          <img src="/bidd_logo.svg" alt="bidd-logo" />
        </div>

        <div className={styles.linksContainer}>
          <div className={styles.navLinks}>
            {isAdmin && (
              <div
                className={`${styles.linkContainer} ${styles.active}`}
                onClick={() => navigate("/minar-sidur/users")}
              >
                Notendur
              </div>
            )}
            <div
              className={styles.skraBiddButton}
              onClick={() => navigate("/minar-sidur/skra-utbod")}
            >
              Skrá útboð
            </div>

            {/* <div className={styles.linkContainer}>Statistics</div> */}
          </div>

          <div className={styles.searchBarContainer}>
            {/* icon */}
            <img src="/icons/bidd/search_header.svg" alt="search-icon" />
            {/* input */}
            <input
              type="text"
              placeholder="Leita.."
              className={styles.searchInput}
            />
          </div>

          <div className={styles.profileContainer}>
            <DropDownLoggedInHeader />
          </div>
        </div>

        <div
          className={`${styles.hamburger} ${
            toggleHamburgerMenuModal == true && styles.selectedHamburger
          }`}
          onClick={() => setToggleHamburgerMenuModal(!toggleHamburgerMenuModal)}
        >
          <img src="/icons/bidd/hamburger.svg" alt="bidd-logo" />
        </div>

        <MenuModalLoggedIn
          toggleModal={toggleHamburgerMenuModal}
          setToggleModal={setToggleHamburgerMenuModal}
        />
      </div>
    </div>
  );
}
