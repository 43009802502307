const industryList = [
    'A Landbúnaður, skógrækt og fiskveiðar',
    'B Námugröftur og vinnsla hráefna úr jörð',
    'C Framleiðsla',
    'D Rafmagns-, gas- og hitaveitur',
    'E Vatnsveita, fráveita, meðhöndlun úrgangs og afmengun',
    'F Byggingarstarfsemi og mannvirkjagerð',
    'G Heild- og smásöluverslun, viðgerðir á vélknúnum ökutækjum',
    'H Flutningar og geymsla',
    'I Rekstur gististaða og veitingarekstur',
    'J Upplýsingar og fjarskipti',
    'K Fjármála- og vátryggingastarfsemi',
    'L Fasteignaviðskipti',
    'M Sérfræðileg, vísindaleg og tæknileg starfsemi',
    'N Leigustarfsemi og ýmis sérhæfð þjónusta',
    'P Fræðslustarfsemi',
    'Q Heilbrigðis- og félagsþjónusta',
    'R Menningar-, íþrótta- og tómstundastarfsemi',
    'S Félagasamtök og önnur þjónustustarfsemi',
    'X Óþekkt starfsemi',
  ];
  
  export default industryList;
  