import React, { useState } from "react";
import styles from "../styles/Header.module.css";
import { useNavigate } from "react-router-dom";
import HeaderDropDown from "./HeaderDropDown";
import HamburgerMenuModal from "./HamburgerMenuModal";

export default function Header() {
  const navigate = useNavigate();

  const [toggleHamburgerMenuModal, setToggleHamburgerMenuModal] =
    useState(false);

  const [showDropdown, setShowDropdown] = useState(false); // Add state for dropdown visibility

  const handleMouseEnter = () => {
    // Event handler for mouse hover on the "Skrá inn" button
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    // Event handler for mouse leaving the "Skrá inn" button
    setShowDropdown(false);
  };

  return (
    <div className={styles.navBar}>
      <div className={styles.navContent}>
        <div className={styles.logo} onClick={() => navigate("/")}>
          <img src="/bidd_logo.svg" alt="bidd-logo" />
        </div>

        <div className={styles.linksContainer}>
          <div className={styles.navLinks}>
            {/* <div className={styles.link}>Heim</div>
            <div className={styles.link}>Útboð</div>
            <HeaderDropDown />
            <div className={styles.link}>Teymið</div> */}
          </div>

          <div className={styles.otherLinks}>
            {/* <div
              className={styles.accesslink}
              onClick={() => navigate("/login")}
            >
              Búa til aðgang
            </div> */}
            
          </div>
        </div>

        {/* <div
          className={`${styles.hamburger} ${
            toggleHamburgerMenuModal == true && styles.selectedHamburger
          }`}
          onClick={() => setToggleHamburgerMenuModal(!toggleHamburgerMenuModal)}
        >
          <img src="/icons/bidd/hamburger.svg" alt="logo" />
        </div>

        <HamburgerMenuModal
          toggleModal={toggleHamburgerMenuModal}
          setToggleModal={setToggleHamburgerMenuModal}
        /> */}
      </div>
    </div>
  );
}
