import NewBidForm from "./NewBidForm";
import { useGetUsersQuery } from "../users/usersApiSlice";
// use get bids
import { useGetBidsQuery } from "./bidsApiSlice";

import PulseLoader from "react-spinners/PulseLoader";
import useTitle from "../../hooks/useTitle";
import useAuth from "../../hooks/useAuth";
import { useParams } from "react-router-dom";
import { useGetDocumentsQuery } from "../documents/documentsApiSlice";

const EditBid = () => {
  useTitle("bidd - Edit Bid");

  const { id } = useParams();

  // const { users } = useGetUsersQuery("usersList", {
  //   selectFromResult: ({ data }) => ({
  //     users: data?.ids.map((id) => data?.entities[id]),
  //   }),
  // });

  // use get bids
  const { bid } = useGetBidsQuery("bidsList", {
    selectFromResult: ({ data }) => ({
      bid: data?.entities[id],
    }),
  });

  const { documents } = useGetDocumentsQuery("documentsList", {
    selectFromResult: ({ data }) => {
      var bidDocuments = [];

      for (const key in data?.entities) {
        if (data?.entities[key].bid === bid.id) {
          bidDocuments.push(data?.entities[key]);
        }
      }

      return {
        documents: bidDocuments,
      };
    },
  });

  // console.log("documents", documents);

  const { name, id: userId } = useAuth();

  // const userId = users?.find((user) => user.name === name)?.id;

  // if (!users?.length) return <PulseLoader color={"#FFF"} />

  const content = (
    <NewBidForm userId={userId} bid={bid} documents={documents} />
  ); //<NewNoteForm users={users} />

  return content;
};
export default EditBid;
