const typeList = [
    'Forauglýsing',
    'Framkvæmd',
    'Gagnvirkt innkaupakerfi (DPS)',
    'Leiguhúsnæði',
    'Markaðskönnun - RFI',
    'Rammasamningar',
    'Verðfyrirspurn',
    'Vörukaup',
    'Þjónusta',
  ];
  
  export default typeList;
  