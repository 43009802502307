import React, { useEffect } from "react";
import styles from "../../styles/Login.module.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import InputForm from "../../components/LoginInputForm/InputForm";

const Login = ({ authResponse }) => {
  useEffect(() => {
    if (authResponse?.url) {
      /* eslint-disable */
      const identity = new DokobitIdentity({
        sessionToken: authResponse.session_token,
        locale: "is",
      }).init();
    }
  }, [authResponse]);

  return (
    <>
      <Header />
      <div className={styles.mainContainer}>
        {/* login Container */}
        <div className={styles.loginContainer}>
          <div className={styles.loginDetailsContainer}>
            {/* title */}
            <h1 className={styles.title}>Mínar síður</h1>
            <h4 className={styles.signupTxt}>
              Þetta er þitt vefsvæði hjá bidd. Hér getur þú.....
            </h4>
            <InputForm />
          </div>
        </div>
        {/* image */}
        <div className={styles.imageContainer} />
      </div>
      <Footer />
    </>
  );
};

export default Login;
