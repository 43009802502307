import useAuth from "../../hooks/useAuth";
import { useGetBidsQuery } from "../../features/bid/bidsApiSlice";
import styles from "../../styles/HomeLoggedIn.module.css";
import { useNavigate } from "react-router-dom";
import FilterDropDown from "../../components/FilterDropDown";
import { useState } from "react";
import { useEffect } from "react";

import industryList from "../../lists/industryList";
import typeList from "../../lists/typeList";

const HomeLoggedIn = () => {
  const navigate = useNavigate();
  const { id, name, isManager, isAdmin } = useAuth();
  const {
    data: bids,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetBidsQuery("bidsList", {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [userBids, setUserBids] = useState([]);
  const [filteredBids, setFilteredBids] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      const { ids, entities } = bids;

      var userBids = [];
      for (const key in entities) {
        userBids.push(entities[key]);
      }
    }
    setUserBids(userBids);
    setFilteredBids(userBids);
  }, [isSuccess]);

  const [filterOptions, setFilterOptions] = useState({
    Verð: [
      {
        From: null,
        To: null,
        check: false,
        name: "Range",
      },
    ],
    Tegund: typeList.map((type) => {
      return {
        check: false,
        name: type,
      };
    }),
    Atvinnugrein: industryList.map((industry) => {
      return {
        check: false,
        name: industry,
      };
    }),
  });

  useEffect(() => {
    if (filterOptions) {
      const newFilteredBids = filterBidsWithFilters(userBids, filterOptions);
      setFilteredBids(newFilteredBids);
    }
  }, [filterOptions, userBids]);

  function filterBidsWithFilters(bids, filters) {
    var newFilteredBids = [];

    bids?.filter((bid) => {
      if (filters.Verð[0].check) {
        if (bid.cost < filters.Verð[0].From || bid.cost > filters.Verð[0].To) {
          return;
        }
      }

      //check if some industry is checked in filters and some type is checked in filters
      if (
        filters.Atvinnugrein.some((industry) => industry.check) &&
        filters.Tegund.some((type) => type.check)
      ) {
        if (
          filterOptions.Atvinnugrein.some(
            (option) => option.name === bid.industry && option.check
          ) &&
          filterOptions.Tegund.some(
            (option) => option.name === bid.type && option.check
          )
        ) {
          newFilteredBids.push(bid);
        }
        return;
      }

      //check if some industry is checked in filters
      if (filters.Atvinnugrein.some((industry) => industry.check)) {
        if (
          filterOptions.Atvinnugrein.some(
            (option) => option.name === bid.industry && option.check
          )
        ) {
          newFilteredBids.push(bid);
        }
        return;
      }

      //check if some type is checked in filters

      if (filters.Tegund.some((type) => type.check)) {
        if (
          filterOptions.Tegund.some(
            (option) => option.name === bid.type && option.check
          )
        ) {
          newFilteredBids.push(bid);
        }
        return;
      }

      newFilteredBids.push(bid);
    });

    return newFilteredBids;
  }

  // console.log("userBids", userBids);
  // console.log("filteredBids", filteredBids);
  // console.log("filterOptions", filterOptions);

  return (
    <>
      <div className={styles.mainContainer}>
        {/* contentContainer */}
        <div className={styles.contentContainer}>
          {/* sidebar */}
          <div className={styles.sidebarContainer}>
            {/* menu Container */}
            <div className={styles.menuContainer}>
              {/* filter */}
              <div className={styles.filter}>
                {/* icon */}
                <img src="/icons/bidd/filter_icon_sidebar.svg" alt="filter" />
                <p>Síur</p>
              </div>
              {/* divider */}
              <div className={styles.sidebarDivider} />

              {/* menu title */}
              {/* <div className={styles.menuTitle}>
                <p>Menu</p>
              </div> */}
              <div className={styles.sizeOptionsContainer}>
                <FilterDropDown
                  title={"Verð"}
                  options={filterOptions.Verð}
                  setFilterOptions={setFilterOptions}
                />
              </div>

              <div className={styles.sizeOptionsContainer}>
                <FilterDropDown
                  title={"Tegund"}
                  options={filterOptions.Tegund}
                  setFilterOptions={setFilterOptions}
                />
              </div>

              <div className={styles.sizeOptionsContainer}>
                <FilterDropDown
                  title={"Atvinnugrein"}
                  options={filterOptions.Atvinnugrein}
                  setFilterOptions={setFilterOptions}
                />
              </div>
            </div>
          </div>

          {/* page content */}
          <div className={styles.pageContentContainer}>
            {/* projects content */}
            <div className={styles.projectsContentContainer}>
              {/* projects title */}
              <div className={styles.projectsTitle}>Öll útboð</div>
              {/* divider */}
              <div className={styles.projectsDivider} />
              {/* projects */}
              <div className={styles.projectsGrid}>
                {filteredBids?.length > 0 &&
                  filteredBids.map((bid) => (
                    <div
                      className={styles.projectContainer}
                      key={bid._id}
                      // onClick={() => {
                      //   if (isManager) {
                      //     navigate(`/minar-sidur/skra-utbod/${bid._id}`);
                      //   } else {
                      //     navigate(`/minar-sidur/bid/${bid._id}`);
                      //   }
                      // }}
                      onClick={() => navigate(`/minar-sidur/bid/${bid._id}`)}
                    >
                      <div className={styles.imageContainer}>User Image</div>
                      <div className={styles.projectDetails}>
                        <div className={styles.projectName}>{bid.title}</div>
                        <div className={styles.projectDescription}>
                          {bid.description?.blocks[0].text + " ..."}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeLoggedIn;
