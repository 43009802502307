import styles from "../styles/MenuModalLoggedIn.module.css";
import DropDownLoggedInHeader from "./DropDownLoggedInHeader";
import HeaderDropDown from "./HeaderDropDown";

export default function MenuModalLoggedIn({ toggleModal, setToggleModal }) {
  return (
    <div
      onClick={() => setToggleModal(!toggleModal)}
      className={`${styles.modal} ${toggleModal && styles.active}`}
    >
      {/* <!-- Modal content --> */}
      <div
        className={`${styles.modal_content} ${
          toggleModal == false ? styles.inactive : ""
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className={styles.closeBtn}
          onClick={() => setToggleModal(!toggleModal)}
        >
          <img src="/icons/bidd/close_cross.svg" className={styles.closeIcon} />
        </div>

        <div className={styles.linksContainer}>
          <div className={styles.profileContainer}>
            <DropDownLoggedInHeader />
          </div>
          <div className={styles.navLinks}>
            <div className={`${styles.linkContainer} ${styles.active}`}>
              Home
            </div>
            <div className={styles.linkContainer}>Create</div>
            <div className={styles.linkContainer}>Statistics</div>
          </div>

          <div className={styles.otherLinks}>
            <div className={styles.searchBarContainer}>
              {/* icon */}
              <img src="/icons/bidd/search_header.svg" alt="search-icon" />
              {/* input */}
              <input
                type="text"
                placeholder="Leita.."
                className={styles.searchInput}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
