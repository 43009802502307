import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Box,
  FormControlLabel,
  Checkbox,
  IconButton,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const DockumentReqModal = ({
  onSubmit,
  formCheckedDocumentsReq,
  dockuments,
  setDockuments,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [newDockument, setNewDockument] = useState("");
  const [checkedDockuments, setCheckedDockuments] = useState({});

  useEffect(() => {
    setCheckedDockuments({ ...formCheckedDocumentsReq });
  }, [formCheckedDocumentsReq]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleAddDockument = () => {
    if (newDockument) {
      setDockuments([...dockuments, newDockument]);
      setCheckedDockuments({ ...checkedDockuments, [newDockument]: true });
      setNewDockument("");
    }
  };

  const handleToggleDockument = (dockument) => {
    setCheckedDockuments((prevCheckedDockuments) => ({
      ...prevCheckedDockuments,
      [dockument]: !prevCheckedDockuments[dockument],
    }));
  };

  const handleComplete = () => {
    onSubmit(checkedDockuments); // Pass checkedDockuments to the parent form
    handleCloseModal();
  };

  return (
    <div>
      {/* Your other form fields */}
      <Button variant="contained" onClick={handleOpenModal}>
        Skrá nauðsynleg skjöl
      </Button>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "4px",
          }}
        >
          <h2>Nauðsynleg skjöl</h2>
          {dockuments.map((dockument, index) => {
            if (
              dockument === "" ||
              dockument === undefined ||
              dockument === null
            )
              return;
            return (
              <div key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedDockuments[dockument] || false}
                      onChange={() => handleToggleDockument(dockument)}
                    />
                  }
                  label={dockument}
                />
              </div>
            );
          })}
          <TextField
            value={newDockument}
            onChange={(e) => setNewDockument(e.target.value)}
            label="Nýtt nauðsynlegt skjal.."
            variant="outlined"
            fullWidth
          />
          <IconButton onClick={handleAddDockument} sx={{ color: "#78ca72" }}>
            <AddIcon />
            <Box sx={{ color: "#78ca72" }}>Skrá nýtt nauðsynlegt skjal</Box>
          </IconButton>
          <br />
          <Box sx={{ marginTop: "20px", float: "right" }}>
            <Button variant="contained" onClick={handleComplete}>
              Loka
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default DockumentReqModal;
